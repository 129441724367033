export const useAuthTokens = () => {
  const { $queryTokenWithClientFlow, $queryTokenWithServerFlow } = useNuxtApp()
  const { jwtData, jwtSignature, refreshToken, deviceId, setJwtCookies } =
    useAuthCookie()

  const getAccessToken = () => {
    const data = jwtData.value
    const signature = jwtSignature.value

    if (!data || !signature) return

    return `${data}.${signature}`
  }

  const updateTokens = async () => {
    if (import.meta.server) {
      const token = getAccessToken()

      if (!token) {
        throw createError({
          statusCode: 401,
          message: 'Unauthorized',
        })
      }

      const { access_token, refresh_token } = await $queryTokenWithServerFlow({
        token,
        refreshToken: refreshToken.value as string,
        deviceId: deviceId.value as string,
      })

      setJwtCookies(access_token, refresh_token)
    } else {
      await $queryTokenWithClientFlow()
    }
  }

  return {
    getAccessToken,
    updateTokens,
  }
}
