<template>
  <div v-if="promotions.length > 0" class="promotion-banner">
    <div class="promotion-banner-wrapper">
      <svg xmlns="http://www.w3.org/2000/svg" class="logo">
        <path
          d="M10.371 37.706c.058-.052.129-.097.171-.157 3.05-4.338 4.857-8.973 4.713-14.098-.075-2.636-.975-5.159-1.357-7.752-.438-2.953-.745-5.899-.267-8.87.183-1.133.569-2.223.908-3.329a.759.759 0 0 0-.25.221c-3.914 5.234-5.205 10.877-3.643 16.971.746 2.908 1.206 5.853 1.225 8.828.019 2.777-.36 5.522-1.5 8.188v-.002z"
        />
      </svg>
      <TransitionGroup name="spin-up" tag="ul" class="promotion-list">
        <li
          v-for="(promotion, index) in promotions"
          v-show="index === bannerIndex"
          :key="promotion.id"
          class="promotion-item"
        >
          <component
            :is="promotion.urlPath ? UiSmartLink : 'div'"
            :to="promotion.urlPath"
            :target="promotion?.isOpenInNewTab ? '_blank' : '_self'"
            class="promotion-link"
          >
            <p class="promotion-title">
              {{ promotion.title }}
            </p>

            <span v-if="promotion.subtitle" class="promotion-subtitle">
              {{ promotion.subtitle }}
            </span>
          </component>
        </li>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useWindowFocus } from '@vueuse/core'
import type { PromotionBannerDTO } from '~/types/api/bff/web/contentful/component.types'
import UiSmartLink from '@sephora-asia/ui-smart-link'

const { promotions } = defineProps<{
  promotions: PromotionBannerDTO[]
}>()

const bannerIndex = ref(0)
const spinnerInterval = ref<ReturnType<typeof setInterval> | null>(null)
const SPINNER_INTERVAL = 2300

const isWindowFocused = useWindowFocus()

watch(isWindowFocused, focused => {
  if (focused) {
    startSpinner()
  } else {
    stopSpinner()
  }
})

function startSpinner() {
  stopSpinner()
  if (promotions.length <= 1) return

  spinnerInterval.value = setInterval(() => {
    bannerIndex.value = (bannerIndex.value + 1) % promotions.length
  }, SPINNER_INTERVAL)
}

function stopSpinner() {
  if (spinnerInterval.value) clearInterval(spinnerInterval.value)
}

onMounted(() => {
  startSpinner()
})

onUnmounted(() => {
  stopSpinner()
})
</script>

<style lang="scss" scoped>
div,
p,
a,
ul {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@include media(mobile, tablet) {
  .logo,
  .promotion-subtitle {
    display: none;
  }
}

.promotion-banner-wrapper {
  display: flex;
  width: 100%;

  .logo {
    width: 25px;
    height: auto;
    aspect-ratio: 5/8;
  }

  .promotion-list {
    list-style: none;
    position: relative;
    overflow: hidden;
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;

    @include media(mobile, tablet) {
      width: 100%;
      height: 20px;
      background: $color-base-black;
      color: $color-base-white;
    }

    .promotion-item {
      width: 100%;
      height: 100%;
      white-space: nowrap;

      .promotion-link {
        height: 100%;
        display: block;
        align-content: center;
        text-decoration: none;
        color: inherit;

        .promotion-title {
          @extend .body-l;
          font-weight: 500;
          line-height: 1.2em;
          letter-spacing: 2px;

          @include media(mobile, tablet) {
            font-size: $size-typescale-body-s-size;
            letter-spacing: $size-typescale-body-s-spacing;
            font-weight: $size-typescale-body-s-weight;
            padding-inline: 16px;
            text-align: center;
          }
        }

        .promotion-subtitle {
          @extend .body-s;
          line-height: 1.2em;
        }
      }
    }
  }
}

.spin-up-enter-active,
.spin-up-leave-active {
  transition: transform 0.3s ease-in-out;
}

.spin-up-enter-from {
  transform: translateY(100%);
  position: absolute;
}

.spin-up-leave-to {
  transform: translateY(-100%);
  position: absolute;
}
</style>
