import canonicalizeUrl from '~/utils/seo/canonical/canonical-url-helper'
import type {
  LayoutEntrySeoDTO,
  ContentEntrySeoDTO,
} from '~/types/api/bff/web/contentful/seo.types.js'
import useReactiveRequestUrl from '~/composables/utils/url/use-reactive-request-url'

export function useLayoutSeoMeta(seo: ComputedRef<LayoutEntrySeoDTO>) {
  useHead({
    meta: [
      {
        property: 'fb:page_id',
        content: seo.value.fbPageId,
      },
      {
        property: 'fb:app_id',
        content: seo.value.fbAppId,
      },
      {
        property: 'al:android:url',
        content: seo.value.androidAppUrl,
      },
      {
        property: 'al:android:package',
        content: seo.value.androidAppPackage,
      },
      {
        property: 'al:android:app_name',
        content: seo.value.androidAppName,
      },
      {
        property: 'al:ios:url',
        content: seo.value.iosAppUrl,
      },
      {
        property: 'al:ios:app_store_id',
        content: seo.value.iosAppStoreId,
      },
      {
        property: 'al:ios:app_name',
        content: seo.value.iosAppName,
      },
    ],
  })
}

export function usePageSeoMeta(seo: ComputedRef<ContentEntrySeoDTO>) {
  const { t } = useI18n()

  const countryCode = useI18nLocale().countryCode.toLowerCase()

  const url = useReactiveRequestUrl()

  const canonicalUrl = computed(() =>
    canonicalizeUrl(url.value, countryCode, {
      showPageQuery: true,
    })
  )

  const documentTitle = computed(
    () => seo.value.title || t(`root.seoMeta.title.${countryCode}`)
  )

  const metaTitle = computed(() =>
    seo?.value.title
      ? `${seo.value.title} | ${t(`root.seoMeta.title.${countryCode}`)}`
      : t(`root.seoMeta.title.${countryCode}`)
  )

  const metaDescription = computed(
    () => seo?.value.description || t(`root.seoMeta.description.${countryCode}`)
  )

  useHead({
    title: documentTitle,
    meta: [
      {
        property: 'og:title',
        content: metaTitle,
      },
      {
        name: 'description',
        content: metaDescription,
      },
      {
        property: 'og:description',
        content: metaDescription,
      },
      {
        property: 'og:url',
        content: url,
      },
    ],
    link: [
      {
        rel: 'canonical',
        id: 'meta-canonical',
        'data-n-head': 'ssr',
        href: canonicalUrl,
      },
    ],
  })
}
