import type { $Fetch } from 'nitropack'
import { QueriesLock } from '../utils/queries-lock'
import type { NuxtError } from '#app'

export default defineNuxtPlugin({
  name: 'fetch-provider-plugin',
  dependsOn: [
    'default-fetch-provider-plugin',
    'server-flow-token-query-plugin',
    'client-flow-token-query-plugin',
  ],
  async setup() {
    const lock = new QueriesLock()

    const fetchProvider = async <T>(
      url: Parameters<$Fetch>[0],
      options: Parameters<$Fetch>[1]
    ): ReturnType<$Fetch<T>> => {
      const { $defaultFetchProvider } = useNuxtApp()
      const { interceptError } = useAuthInterceptor(url, options, lock)

      if (lock.isLocked()) {
        return lock.wait(() => $defaultFetchProvider(url, options))
      }

      try {
        return await $defaultFetchProvider(url, options)
      } catch (error) {
        return interceptError(error as NuxtError)
      }
    }

    return {
      provide: {
        fetchProvider,
      },
    }
  },
})
