import { useQuery } from '@tanstack/vue-query'
import { JWT_DATA_COOKIE_KEY } from '~/constants/auth.constants'

// exporting as async function to avoid hydration issues
export default async function useCurrentUser() {
  const isJwtDataCookiePresent = !!useCookie(JWT_DATA_COOKIE_KEY).value

  const {
    data: user,
    isSuccess,
    suspense,
  } = useQuery({
    ...useGetLuxolaUserAccountOptions(),
    enabled: isJwtDataCookiePresent,
    retry: false,
  })

  isJwtDataCookiePresent && (await suspense())

  const isLoggedIn = computed(() => !!user.value)

  const currentUser = computed(() =>
    isLoggedIn.value ? user.value : undefined
  )

  return {
    isLoggedIn,
    isSuccess,
    currentUser,
  }
}
