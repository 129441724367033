<template>
  <section class="layout-preview-container">
    <PreviewSettingNavigation
      :country="previewOptions.country"
      :locale="previewOptions.locale"
      :is-logged-in="previewOptions.isLoggedIn"
      :selected-user-segments="previewOptions.selectedUserSegments"
      @on-country-change="previewOptions.country = $event"
      @on-locale-change="previewOptions.locale = $event"
      @on-is-logged-in-change="previewOptions.isLoggedIn = $event"
      @on-user-segments-change="previewOptions.selectedUserSegments = $event"
    />
    <NuxtPage />
  </section>
</template>
<script lang="ts" setup>
import {
  type PreviewLayoutMeta,
  injectionKey,
} from '~/composables/preview/use-preview-layout-meta'

// Init Default Values
const previewOptions = ref<PreviewLayoutMeta>({
  country: 'SG',
  locale: 'en',
  isLoggedIn: false,
  selectedUserSegments: [],
})

provide(injectionKey, previewOptions)
</script>

<style lang="scss" scoped></style>
