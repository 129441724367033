import type { RecentSearch } from '~/types/api/bff/web/search/search.types'
import { defineStore } from 'pinia'

export const useHeaderSearchStore = defineStore('headerSearchStore', {
  state: () => ({
    recentSearches: [],
  }),

  getters: {
    getRecentSearches: state => state.recentSearches,
  },

  actions: {
    fetchRecentSearches() {
      let recentSearches

      try {
        const item = localStorage.getItem('recentSearches')
        recentSearches = item ? JSON.parse(item) : []
      } catch {
        recentSearches = []
      }

      this.recentSearches = recentSearches
    },
    saveRecentSearches(searchItem: RecentSearch) {
      let currentRecentSearches = []

      // fetch the current recent search list from local storage
      try {
        const item = localStorage.getItem('recentSearches')
        currentRecentSearches = item ? JSON.parse(item) : []
      } catch {
        currentRecentSearches = []
      }

      // find duplicate search item in current recent search array list and remove it
      if (currentRecentSearches.length > 0) {
        const recentSearchIndex = currentRecentSearches.findIndex(
          (s: RecentSearch) =>
            s.name.toLowerCase() === searchItem.name.toLowerCase()
        )
        if (recentSearchIndex !== -1) {
          currentRecentSearches.splice(recentSearchIndex, 1)
        }
      }

      // push search item into top of the array list
      currentRecentSearches.unshift(searchItem)

      // remove extra recent searches (max 5)
      if (currentRecentSearches.length > 5) {
        currentRecentSearches.pop()
      }

      this.recentSearches = currentRecentSearches

      // save the updated recent search list to local storage
      localStorage.setItem(
        'recentSearches',
        JSON.stringify(currentRecentSearches)
      )
    },
    clearRecentSearches() {
      this.recentSearches = []
      localStorage.removeItem('recentSearches')
    },
  },
})
