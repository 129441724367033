<template>
  <div :class="`${pageType}-container`">
    <section v-for="(component, index) in components" :key="component.id">
      <component
        :is="componentMap[component.referenceKey]"
        :component-position="index + 1"
        :data-entry-id="component.id"
        v-bind="component"
        :class="component.type"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query'
import type { AsyncComponentLoader, Component } from 'vue'
import type { PageMetaKey } from '~/types/pages/page-meta-key.types'

interface PageProps {
  pageType: PageMetaKey
}

defineProps<PageProps>()

/**
 * Lazily imports all components from the 'components/contentful' directory, excluding this file.
 * This enables Rollup to create separate chunks for each component
 * Reference: https://vite.dev/guide/features.html#glob-import
 **/
const dynamicImportMap = import.meta.glob(['./*.vue', '!./dynamic-page.vue'])
const componentMap = {} as Record<string, Component>

Object.keys(dynamicImportMap).forEach(path => {
  let componentName = capitalizeEachWord(
    removeDashOrUnderscore(path.replace(/^\.\/|\.vue$/g, ''))
  ).replaceAll(' ', '') as string
  //for e.g  ./billboard-container.vue ->> BillboardContainer

  // Need to update referenceKey for ProductListingContainer to match the component naming convention
  if (componentName !== 'ProductListingContainer')
    componentName = componentName.replace('Container', '')

  componentMap[componentName] = defineAsyncComponent({
    loader: dynamicImportMap[path] as AsyncComponentLoader<Component>,
    /*
     - `loadingComponent: UiAwesomeSkeleton`: Specifies the loader component to use as a fallback.
     Nuxt wraps components in Suspense by default, preventing individual component loading states.
     Set `suspensible: false` to enable individual component loading states.
     - `delay: 200`: Adds a 200ms delay before showing the loader to prevent flickering for fast-loading components.
     - `timeout: 3000`: Sets a maximum timeout of 3000ms for the component to load.
     TODO: Leverage the `lazyHydration` option available in Vue 3.5+ for improved SSR performance.
     */
  })
})

const { data: pageContent, suspense } = useQuery(useGetContentEntry())
const pageMetaStore = usePageMetaStore()

const components = computed(() => pageContent?.value?.components || [])
const seo = computed(() => pageContent?.value?.seo || {})

usePageSeoMeta(seo)

watch(
  () => seo.value.title,
  () => pageMetaStore.setCampaignName(seo.value.title),
  { immediate: true }
)

onServerPrefetch(suspense)
</script>
