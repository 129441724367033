<template>
  <div class="setting-navigation section">
    <div class="container">
      <div class="left-section-container">
        <h2 class="header headline-l">Preview Settings</h2>
      </div>
      <div class="right-section-container">
        <UiDropdown class="nav-dropdown">
          <UiLabel
            text="Session"
            :icons="{
              left: {
                name: 'account-outlined',
              },
            }"
            class="nav-label body-m"
            color="white"
          />
          <template #content>
            <div class="dropdown-item-container">
              <span class="dropdown-item body-m">
                <UiLabel
                  text="Logged In"
                  :icons="
                    isLoggedIn === true
                      ? {
                          right: {
                            name: 'check-outlined',
                          },
                        }
                      : undefined
                  "
                  @click="onUserStateClicked(true)"
                />
              </span>
              <span class="dropdown-item body-m">
                <UiLabel
                  text="Signed Out"
                  :icons="
                    isLoggedIn === false
                      ? {
                          right: {
                            name: 'check-outlined',
                          },
                        }
                      : undefined
                  "
                  @click="onUserStateClicked(false)"
                />
              </span>
            </div>
          </template>
        </UiDropdown>
        <PreviewUserSegmentsDropdown
          @selected-segments="onUserSegmentSelected"
        />
        <UiDropdown class="nav-dropdown">
          <UiLabel
            text="Country"
            :icons="{
              left: {
                name: 'location-outlined',
              },
            }"
            class="nav-label body-m"
            color="white"
          />
          <template #content>
            <div class="dropdown-item-container">
              <span
                v-for="c in Object.keys(AVAILABLE_LANGUAGES)"
                :key="c"
                class="dropdown-item body-m"
              >
                <UiLabel
                  :text="c"
                  :icons="
                    country === c
                      ? {
                          right: {
                            name: 'check-outlined',
                          },
                        }
                      : undefined
                  "
                  @click="onCountryClicked(c)"
                />
              </span>
            </div>
          </template>
        </UiDropdown>
        <UiDropdown class="nav-dropdown">
          <UiLabel
            text="Language"
            :icons="{
              left: {
                name: 'beauty-feed-outlined',
              },
            }"
            class="nav-label body-m"
            color="white"
          />
          <template #content>
            <div class="dropdown-item-container">
              <span
                v-for="l in locales"
                :key="l"
                class="dropdown-item points body-m"
              >
                <UiLabel
                  :text="l.toUpperCase()"
                  :icons="
                    locale === l
                      ? {
                          right: {
                            name: 'check-outlined',
                          },
                        }
                      : undefined
                  "
                  @click="onLocaleClicked(l)"
                />
              </span>
            </div>
          </template>
        </UiDropdown>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import UiDropdown from '@sephora-asia/ui-dropdown'
import UiLabel from '@sephora-asia/ui-label'
import type { PreviewLayoutMeta } from '~/composables/preview/use-preview-layout-meta'
import { AVAILABLE_LANGUAGES } from '~/modules/i18n/runtime/constants/i18n'

const props = defineProps<PreviewLayoutMeta>()
const emit = defineEmits([
  'onIsLoggedInChange',
  'onCountryChange',
  'onLocaleChange',
  'onUserSegmentsChange',
])

const locales = computed(() => {
  return AVAILABLE_LANGUAGES[props.country]
})

function onUserSegmentSelected(value: string[]) {
  emit('onUserSegmentsChange', value)
}

function onUserStateClicked(value: boolean) {
  emit('onIsLoggedInChange', value)
}

function onCountryClicked(value: string) {
  emit('onCountryChange', value)

  emit('onLocaleChange', AVAILABLE_LANGUAGES[value][0] || 'en')
}

function onLocaleClicked(value: string) {
  emit('onLocaleChange', value)
}
</script>

<style lang="scss" scoped>
.setting-navigation {
  background-color: var(--color-base-red-100);
  color: var(--color-base-white);

  .container {
    display: flex;

    .left-section-container,
    .right-section-container {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
      height: 40px;
    }

    @include mobile {
      flex-direction: column;
      height: auto;
    }

    .right-section-container {
      justify-content: flex-end;

      @include mobile {
        justify-content: flex-start;
        gap: 12px 20px;
        height: fit-content;
        flex-wrap: wrap;
        padding-bottom: 12px;
      }

      .nav-label {
        &:hover {
          text-decoration: underline;
          cursor: pointer;

          :deep(.icon-container) {
            svg {
              cursor: pointer;
            }
          }
        }
      }

      :deep(.nav-dropdown) {
        .dropdown-title {
          color: var(--color-base-grey-50);
        }

        .dropdown-item-container {
          display: flex;
          width: 150px;
          padding: 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;

          .dropdown-item {
            width: 100%;

            .icon-container {
              --icon-size: 21px;

              svg.icon-check-outlined {
                fill: var(--color-base-green-n100);
              }
            }

            &:hover {
              cursor: pointer;

              .icon-container {
                svg {
                  cursor: pointer;
                }
              }
            }
          }
        }

        .dropdown-selection-container {
          display: flex;
          padding: 4px;

          .dropdown-tag-container {
            display: flex;
            flex-wrap: wrap;
            width: 150px;
            gap: 2px;

            .dropdown-selection-tag {
              color: var(--color-base-black);
              background-color: var(--color-base-grey-10);
              border-radius: 4px;
              padding: 0px 4px;
              flex-direction: row;
              gap: 2px;
            }
          }
        }

        .dropdown-menu {
          right: 0;
          padding-top: 8px;

          @include mobile {
            right: unset;
          }

          .dropdown-content {
            max-height: min(50vh, 400px);
            overflow: auto;
          }
        }
      }
    }
  }
}
</style>
