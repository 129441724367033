export default function useBaseRoutes() {
  const route = useRoute()

  // .e.g ['', 'preview', 'pages', 'christmas-gift-ideas', 'makeup']
  // or ['', 'pages', 'christmas-gift-ideas', 'makeup']
  const path = computed(() => route.path.split('/'))

  const isIndexRoute = computed(
    () => route.path.replace(/preview\/?/, '') === '/'
  )

  const previewDirectory = computed(() => {
    return (isPreview.value && path.value[1]) || ''
  })

  const pageType = computed(() =>
    isIndexRoute.value
      ? 'homepage'
      : (isPreview.value ? path.value[2] : path.value[1]) || ''
  )

  const slugUrl = computed(() =>
    isIndexRoute.value ? 'homepage' : route.params.slugUrl?.toString() || ''
  )

  // .e.g 'preview/pages/christmas-gift-ideas'
  const baseDirectory = computed(() => {
    return [previewDirectory.value, pageType.value, route.params.slugUrl]
      .filter(Boolean)
      .join('/')
  })

  const isPreview = computed(() => {
    return path.value[1] === 'preview'
  })

  /**
   * - - - - - Internal Functions - - - - -
   */
  // Add _internalFunctions() here.

  return {
    isPreview,
    pageType,
    slugUrl,
    baseDirectory,
  }
}
