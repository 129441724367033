<template>
  <div :id="bannerContainerId" />
</template>

<script lang="ts" setup>
const props = defineProps<{ appsflyerKey: string }>()

const bannerContainerId = 'af-get-app-banner-container'

const isDisableAppsflyer = useState(
  'isDisableAppsflyer',
  () => !!useRequestHeader('x-disable-appsflyer')
)

if (!isDisableAppsflyer.value)
  useHead({
    script: [
      {
        id: 'appsflyer-smartbanner',
        innerHTML: `!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "${props.appsflyerKey}"}});AF('banners', 'showBanner', { bannerContainerQuery: "#${bannerContainerId}", bannerZIndex: 9999, additionalParams: { p1: "v1", p2: "v2"}})`,
        defer: true,
      },
    ],
  })
</script>
<style lang="scss">
.responsive-wrapper {
  z-index: var(--size-z-index-sticky) !important;
}
</style>
