import {
  COUNTRY_CODE_COOKIE_KEY,
  LANGUAGE_CODE_COOKIE_KEY,
  COOKIE_OPTS,
} from '../constants/i18n'
import type { CookieRef } from '#app'

const updateCookieWithValue = (
  cookie: CookieRef<string | null | undefined>,
  value: string
) => {
  const incomingValue = value.toLowerCase()
  if (cookie.value !== incomingValue) {
    cookie.value = incomingValue
  }
}

export const useI18nCookies = () => {
  const countryCookie = useCookie(COUNTRY_CODE_COOKIE_KEY, COOKIE_OPTS)
  const languageCookie = useCookie(LANGUAGE_CODE_COOKIE_KEY, COOKIE_OPTS)

  const setCountryCookie = (value: string) =>
    updateCookieWithValue(countryCookie, value)

  const setLanguageCookie = (value: string) =>
    updateCookieWithValue(languageCookie, `lng_${value}`)

  return {
    countryCookie,
    languageCookie,
    setCountryCookie,
    setLanguageCookie,
  }
}
