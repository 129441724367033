import { createGtm, useGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin(nuxt => {
  const config = useRuntimeConfig()
  const { isPreview } = useBaseRoutes()

  nuxt.vueApp.use(
    createGtm({
      id: config.public.gtm.id || 'GTM-N72VQC',
      defer: true,
      compatibility: false,
      enabled: config.public.gtm.enabled && !isPreview.value,
      loadScript: true,
      trackOnNextTick: false,
    })
  )

  addRouteMiddleware(
    'gtm-router-middleware',
    to => {
      const $gtm = useGtm()
      const pageUrl = useRequestURL().origin + to.fullPath

      if ($gtm?.enabled()) {
        window.dataLayer?.push({
          event: 'page_viewed',
          pageUrl,
        })
      }
    },
    { global: true }
  )
})
