<template>
  <div class="sidebar-item-container">
    <UiAccordion :has-toggle-icon="true">
      <template #header>
        <UiLabel
          :text="$t('layout.header.beautyPass')"
          :icons="{
            left: {
              name: 'beauty-pass-outlined',
            },
          }"
          class="icon-label body-m"
        />
      </template>
      <template #content>
        <div class="accordion-items-container">
          <span
            v-for="link in beautyPassLinks"
            :key="link.to"
            class="accordion-item body-m"
          >
            <UiLabel class="sidebar-item" :text="link.text" :url="link.to" />
          </span>
        </div>
      </template>
    </UiAccordion>
  </div>
</template>

<script lang="ts" setup>
import UiLabel from '@sephora-asia/ui-label'
import UiAccordion from '@sephora-asia/ui-accordion'

const props = defineProps<{
  isUserLoggedIn?: boolean
}>()

const { t } = useI18n()

const beautyPassLinks = computed(() => {
  const link = [
    {
      text: t('layout.header.rewardsBoutique'),
      to: '/rewards-boutique',
    },
    {
      text: t('layout.header.beautyPassBenefits'),
      to: '/beauty-pass',
    },
  ]

  if (props.isUserLoggedIn) {
    link.push({
      text: t('layout.header.pointsSummary'),
      to: '/account/point_logs',
    })
  }

  return link
})
</script>

<style lang="scss" scoped>
.icon-label {
  :deep(.icon-container) {
    margin-right: 12px;
  }
}

.sidebar-item-container {
  .sidebar-item,
  :deep(.accordion-header) {
    padding: 12px 20px;
  }

  .accordion-items-container {
    display: flex;
    flex-direction: column;

    .accordion-item {
      padding-left: 20px;
    }
  }
}
</style>
