<template>
  <div class="search-suggestion-container">
    <div v-if="recentSearches.length > 0" class="recent-searches">
      <div class="title">
        <span class="headline-m">{{
          $t('layout.header.search.recentSearches')
        }}</span>
        <span class="body-m clear-label" @click="clearSearch">{{
          $t('layout.header.search.clear')
        }}</span>
      </div>
      <div class="search-result">
        <UiLabel
          v-for="search in recentSearches"
          :key="search.slug"
          :url="search.slug"
          :text="search.name"
          class="body-m"
        />
      </div>
      <div class="divider-container">
        <UiDivider type="secondary" />
      </div>
    </div>
    <div
      v-if="popularSearches && popularSearches.length > 0"
      class="popular-searches"
    >
      <div class="title">
        <span class="headline-m">{{
          $t('layout.header.search.popularSearches')
        }}</span>
      </div>
      <div class="search-result">
        <UiPill
          v-for="search in popularSearches"
          :key="search.id"
          type="primary"
          :url="search.slugUrl"
          :text="search.name"
          @click="onClickPopularSearch(search)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {
  RecentSearches,
  PopularQueries,
  PopularQuery,
} from '~/types/api/bff/web/search/search.types'
import UiDivider from '@sephora-asia/ui-divider'
import UiLabel from '@sephora-asia/ui-label'
import UiPill from '@sephora-asia/ui-pill'

const { trackEvent } = useGtmTrack()

const props = defineProps<{
  recentSearches: RecentSearches
  popularSearches?: PopularQueries
  query: string
}>()

const headerSearchStore = useHeaderSearchStore()

function clearSearch() {
  headerSearchStore.clearRecentSearches()
}

function onClickPopularSearch(search: PopularQuery) {
  const { name, slugUrl, type } = search

  trackEvent({
    event: 'search_menu_popular_results_click_ssr',
    params: {
      search_term: props.query || '',
      search_result_slug: slugUrl,
    },
  })
  headerSearchStore.saveRecentSearches({
    name,
    type,
    slug: slugUrl,
  })
}
</script>

<style lang="scss" scoped>
.search-suggestion-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  overflow-y: scroll;

  @include media(mobile, tablet) {
    padding: 16px;
  }

  .divider-container {
    width: 100%;
  }

  .recent-searches,
  .popular-searches,
  .popular-brands {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 21.5px;

    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .search-result {
      display: flex;
    }
  }

  .recent-searches {
    .title {
      .clear-label {
        cursor: pointer;
        color: var(--color-base-grey-50);
      }
    }

    .search-result {
      flex-direction: column;
      gap: 11px;
    }
  }

  .popular-searches {
    .search-result {
      flex-direction: row;
      gap: 8px;
      flex-wrap: wrap;
    }
  }

  .popular-brands {
    .search-result {
      flex-direction: row;
      gap: 16px;
      flex-wrap: wrap;

      .search-brand-item-container {
        display: flex;
        height: 64px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid var(--color-base-grey-10);
        background: var(--color-base-white);

        .brand-item {
          width: 77px;
          height: 48px;
        }
      }
    }
  }
}
</style>
