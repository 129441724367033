import { SocialAuthProviders } from '~/types/api/bff/web/auth/social-auth.types'
import { GOOGLE_ONE_TAP_SESSION_STORAGE_KEY } from '~/constants/auth.constants'
import type { ActiveHeadEntry, UseHeadInput, MergeHead } from '@unhead/vue'
import type { DefaultError } from '@tanstack/vue-query'

const GOOGLE_ONE_TAP_TRACKING_LABEL = 'google one tap'

export default async function (clientId: string, isLoggedIn: Ref<boolean>) {
  const scriptRef = ref<ActiveHeadEntry<UseHeadInput<MergeHead>> | null>(null)
  const { socialSignIn } = useSocialAuth()

  function manageGoogleOneTapScript() {
    if (isLoggedIn.value) scriptRef.value?.dispose()
    else
      scriptRef.value = useHead(
        {
          script: [
            {
              id: 'google-one-tap-script',
              src: '//accounts.google.com/gsi/client',
              async: true,
              defer: true,
              onload: initGoogleOneTap,
            },
          ],
        },
        { mode: 'client', tagPriority: 'low' }
      ) as ActiveHeadEntry<UseHeadInput<MergeHead>>
  }

  function initGoogleOneTap() {
    window?.google?.accounts.id.initialize({
      client_id: clientId,
      cancel_on_tap_outside: false,
      callback: oneTapLogin,
    })
    window?.google?.accounts.id.prompt()
  }

  async function oneTapLogin(response: { credential: string }) {
    const providerToken = response?.credential
    if (!providerToken) return

    try {
      await socialSignIn(
        SocialAuthProviders.GOOGLE,
        providerToken,
        GOOGLE_ONE_TAP_TRACKING_LABEL
      )
    } catch (error) {
      const socialSignInError = error as DefaultError
      const errorMeta = socialSignInError?.data?.data?.meta

      if (
        socialSignInError?.statusCode === 401 &&
        errorMeta?.error_type === 'blocked'
      ) {
        // User is blocked from signing in with google one tap
        // This data is shared with luxola & web-ui
        window.sessionStorage.setItem(
          GOOGLE_ONE_TAP_SESSION_STORAGE_KEY,
          JSON.stringify({
            detail: socialSignInError?.data?.data?.errors?.[0]?.detail,
            description: errorMeta?.error_description,
            reference_id: errorMeta?.reference_id,
          })
        )

        return redirectToSignIn()
      }
    }
  }

  onMounted(manageGoogleOneTapScript)

  watch(isLoggedIn, manageGoogleOneTapScript)
}
