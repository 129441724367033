<script lang="ts" setup>
const { trackEvent } = useGtmTrack()
const { countryCode, getCurrentLocale } = useI18nLocale()
const { currentUser } = await useCurrentUser()
const { isMobile } = useDevice()

function setFirstDataLayerElement() {
  const firstElement = {
    user: {
      id: currentUser.value?.id,
      is_employee: currentUser.value?.isEmployee,
      age: currentUser.value?.age,
      card_number: currentUser.value?.cardNumber,
      email_sha256: currentUser.value?.emailSHA256,
      email_md5: currentUser.value?.emailMD5,
      mobile_number_md5: currentUser.value?.mobileNumberMD5,
      loyalty_points: currentUser.value?.loyaltyPoints,
      loyalty_tier: currentUser.value?.loyaltyTier,
      tier_points_counter: currentUser.value?.tierPointsCounter,
    },
    cart: {},
    site: {
      locale: countryCode.toLowerCase(),
      language: getCurrentLocale(),
      mobile: isMobile,
      status: 200,
    },
  }

  window.dataLayer = window.dataLayer || []

  if (!window.dataLayer[0]?.user) {
    window.dataLayer[0] = firstElement
  } else {
    window.dataLayer.unshift(firstElement)
  }

  trackEvent({
    event: 'data_layer_loaded',
  })
}

onMounted(setFirstDataLayerElement)
</script>
