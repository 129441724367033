import {
  useMutation,
  useQueryClient,
  type DefaultError,
} from '@tanstack/vue-query'

import { isSephoraEmail, redirectToSignIn, getEmailFromJwt } from './helpers'
import { userAccountQueryKey } from '~/composables/query/luxola/luxola-account-query-key'
import {
  getBagDataQueryKey,
  getItemsCountQueryKey,
} from '~/composables/query/cart/query-key'

import type {
  SocialAuthPayload,
  SocialAuthProviders,
} from '~/types/api/bff/web/auth/social-auth.types'
import type { AccountData } from '~/types/api/bff/web/luxola/account/account.types'

const queriesToInvalidate = [
  getWaitlistQueryKey()[0],
  getRemindMeQueryKey()[0],
  getWishlistQueryKey()[0],
  getBagDataQueryKey()[0],
  getItemsCountQueryKey()[0],
  contentEntryQueryKey,
  layoutEntryQueryKey,
]

export function useSocialAuth() {
  const { $socialAuthRepository, $i18n } = useNuxtApp()
  const { notify } = useNotification()
  const queryClient = useQueryClient()
  const forterToken = useCookie('forterToken')
  const { trackEvent } = useGtmTrack()

  const { mutateAsync: socialSignInMutation } = useMutation({
    mutationFn: async payload => {
      delete payload.trackingLabel
      return await $socialAuthRepository.create(payload)
    },
    onSuccess: onSocialAuthSuccess,
    onError: onSocialAuthError,
  })

  async function socialSignIn(
    provider: SocialAuthProviders,
    providerToken: string,
    trackingLabel: string,
    mergeCart = true
  ) {
    const email = getEmailFromJwt(providerToken)

    // Okta login is only enabled on production and we cannot check feature flag here coming from split.io
    if (process.env.NODE_ENV == 'production' && isSephoraEmail(email))
      return redirectToSignIn({
        return_to: `/&signin_method=okta&email=${email}`,
      })

    await socialSignInMutation({
      provider,
      providerToken,
      trackingLabel,
      mergeCart,
      forterToken: forterToken.value, // TODO: needs to be updated to window.forterToken once forter is added
    })
  }

  function onSocialAuthSuccess(
    data: AccountData,
    { trackingLabel }: SocialAuthPayload
  ) {
    queryClient.setQueryData(userAccountQueryKey, data)
    queryClient.invalidateQueries({
      predicate: query =>
        queriesToInvalidate.includes(query.queryKey?.[0] as string),
      exact: false,
    })

    notify({
      type: 'success',
      text: $i18n.t('auth.successfulSignIn'),
    })

    trackEvent({
      event: 'User Sign in',
      category: 'User',
      action: 'Sign in',
      label: trackingLabel,
    })
  }

  function onSocialAuthError({ data, statusCode }: DefaultError) {
    // User needs to sign-up before signing in with social provider
    if (
      statusCode === 404 &&
      data?.data?.errors?.[0]?.detail === 'User not found'
    )
      return redirectToSignIn({
        msg: $i18n.t('auth.promptSignUp'),
      })
    else
      notify({
        type: 'error',
        text: $i18n.t('auth.failed'),
      })
  }

  return { socialSignIn }
}
