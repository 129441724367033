import { AVAILABLE_LANGUAGES } from '~/modules/i18n/runtime/constants/i18n.js'

function canonicalizeUrl(
  url: string,
  countryCode: string,
  opts: { showPageQuery: boolean }
) {
  const urlObject = new URL(url)

  const languageQuery = urlObject.searchParams.get('language')
  const pageQuery = urlObject.searchParams.get('page')

  urlObject.search = ''

  if (languageQuery && canAttachLanguage(languageQuery, countryCode)) {
    urlObject.searchParams.append('language', languageQuery)
  }

  if (pageQuery && opts.showPageQuery) {
    urlObject.searchParams.append('page', pageQuery)
  }
  return urlObject.toString()
}

function canAttachLanguage(languageCode: string, countryCode: string) {
  return (
    isLanguageAvailable(languageCode, countryCode) &&
    defaultLanguageCode(countryCode) !== languageCode
  )
}

function isLanguageAvailable(languageCode: string, countryCode: string) {
  return AVAILABLE_LANGUAGES[countryCode.toUpperCase()].includes(languageCode)
}

function defaultLanguageCode(countryCode: string) {
  return AVAILABLE_LANGUAGES[countryCode.toUpperCase()][0].toLowerCase()
}

export default canonicalizeUrl
