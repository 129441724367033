import { default as aboutv3Wuob7z2aMeta } from "/app/src/pages/about.vue?macro=true";
import { default as _91id_93PuHqDiWHExMeta } from "/app/src/pages/happening/classes/[id].vue?macro=true";
import { default as _91id_93hfgSaDDN3WMeta } from "/app/src/pages/happening/classes/cancel/[id].vue?macro=true";
import { default as _91id_93AwFM9HQOYKMeta } from "/app/src/pages/happening/events/[id].vue?macro=true";
import { default as _91id_93a4pVmoKDCAMeta } from "/app/src/pages/happening/events/cancel/[id].vue?macro=true";
import { default as _91id_93AMnG6OYBZaMeta } from "/app/src/pages/happening/services/[id].vue?macro=true";
import { default as _91id_93iQITqvSCrWMeta } from "/app/src/pages/happening/services/cancel/[id].vue?macro=true";
import { default as indexcVBZ7STCAPMeta } from "/app/src/pages/happening/services/index.vue?macro=true";
import { default as _91id_93FWvt29jNWVMeta } from "/app/src/pages/happening/services/reschedule/[id].vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as _91_91subCategory_93_93F9JDGPiTyUMeta } from "/app/src/pages/pages/[[slugUrl]]/[[topCategory]]/[[category]]/[[subCategory]].vue?macro=true";
import { default as indexur5R1v27A1Meta } from "/app/src/pages/preview/homepage/[[slugUrl]]/index.vue?macro=true";
import { default as layoutXImpamOJP3Meta } from "/app/src/pages/preview/layout.vue?macro=true";
import { default as _91_91subCategory_93_93QhQLWaVvyLMeta } from "/app/src/pages/preview/pages/[[slugUrl]]/[[topCategory]]/[[category]]/[[subCategory]].vue?macro=true";
import { default as _91_91subCategory_93_93Yuq7GPCQM3Meta } from "/app/src/pages/sandbox/[[slugUrl]]/[[topCategory]]/[[category]]/[[subCategory]].vue?macro=true";
import { default as api_45server_45renderedbkt26AKw7fMeta } from "/app/src/pages/sandbox/api-server-rendered.vue?macro=true";
import { default as device_45detection7YYMdypBKsMeta } from "/app/src/pages/sandbox/device-detection.vue?macro=true";
import { default as indexGzlxGnLsh8Meta } from "/app/src/pages/sandbox/index.vue?macro=true";
import { default as language6LN7xQUiwgMeta } from "/app/src/pages/sandbox/language.vue?macro=true";
import { default as layout_45bffQO7xHlFPKrMeta } from "/app/src/pages/sandbox/layout-bff.vue?macro=true";
import { default as notification1Na6AsWbynMeta } from "/app/src/pages/sandbox/notification.vue?macro=true";
import { default as storesnAJpziZtCEMeta } from "/app/src/pages/sandbox/stores.vue?macro=true";
import { default as ui_45componentsYOcjffDGuzMeta } from "/app/src/pages/sandbox/ui-components.vue?macro=true";
import { default as useElementVisibilitymyvdsIEoSUMeta } from "/app/src/pages/sandbox/useElementVisibility.vue?macro=true";
import { default as vue_45useakdLalrBx5Meta } from "/app/src/pages/sandbox/vue-use.vue?macro=true";
import { default as indexqdwoTIJZnaMeta } from "/app/src/pages/swagger/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/app/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "happening-classes-id",
    path: "/happening/classes/:id()",
    meta: _91id_93PuHqDiWHExMeta || {},
    component: () => import("/app/src/pages/happening/classes/[id].vue").then(m => m.default || m)
  },
  {
    name: "happening-classes-cancel-id",
    path: "/happening/classes/cancel/:id()",
    meta: _91id_93hfgSaDDN3WMeta || {},
    component: () => import("/app/src/pages/happening/classes/cancel/[id].vue").then(m => m.default || m)
  },
  {
    name: "happening-events-id",
    path: "/happening/events/:id()",
    meta: _91id_93AwFM9HQOYKMeta || {},
    component: () => import("/app/src/pages/happening/events/[id].vue").then(m => m.default || m)
  },
  {
    name: "happening-events-cancel-id",
    path: "/happening/events/cancel/:id()",
    meta: _91id_93a4pVmoKDCAMeta || {},
    component: () => import("/app/src/pages/happening/events/cancel/[id].vue").then(m => m.default || m)
  },
  {
    name: "happening-services-id",
    path: "/happening/services/:id()",
    meta: _91id_93AMnG6OYBZaMeta || {},
    component: () => import("/app/src/pages/happening/services/[id].vue").then(m => m.default || m)
  },
  {
    name: "happening-services-cancel-id",
    path: "/happening/services/cancel/:id()",
    meta: _91id_93iQITqvSCrWMeta || {},
    component: () => import("/app/src/pages/happening/services/cancel/[id].vue").then(m => m.default || m)
  },
  {
    name: "happening-services",
    path: "/happening/services",
    meta: indexcVBZ7STCAPMeta || {},
    component: () => import("/app/src/pages/happening/services/index.vue").then(m => m.default || m)
  },
  {
    name: "happening-services-reschedule-id",
    path: "/happening/services/reschedule/:id()",
    meta: _91id_93FWvt29jNWVMeta || {},
    component: () => import("/app/src/pages/happening/services/reschedule/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "pages-slugUrl-topCategory-category-subCategory",
    path: "/pages/:slugUrl?/:topCategory?/:category?/:subCategory?",
    meta: _91_91subCategory_93_93F9JDGPiTyUMeta || {},
    component: () => import("/app/src/pages/pages/[[slugUrl]]/[[topCategory]]/[[category]]/[[subCategory]].vue").then(m => m.default || m)
  },
  {
    name: "preview-homepage-slugUrl",
    path: "/preview/homepage/:slugUrl?",
    meta: indexur5R1v27A1Meta || {},
    component: () => import("/app/src/pages/preview/homepage/[[slugUrl]]/index.vue").then(m => m.default || m)
  },
  {
    name: "preview-layout",
    path: "/preview/layout",
    meta: layoutXImpamOJP3Meta || {},
    component: () => import("/app/src/pages/preview/layout.vue").then(m => m.default || m)
  },
  {
    name: "preview-pages-slugUrl-topCategory-category-subCategory",
    path: "/preview/pages/:slugUrl?/:topCategory?/:category?/:subCategory?",
    meta: _91_91subCategory_93_93QhQLWaVvyLMeta || {},
    component: () => import("/app/src/pages/preview/pages/[[slugUrl]]/[[topCategory]]/[[category]]/[[subCategory]].vue").then(m => m.default || m)
  },
  {
    name: "sandbox-slugUrl-topCategory-category-subCategory",
    path: "/sandbox/:slugUrl?/:topCategory?/:category?/:subCategory?",
    meta: _91_91subCategory_93_93Yuq7GPCQM3Meta || {},
    component: () => import("/app/src/pages/sandbox/[[slugUrl]]/[[topCategory]]/[[category]]/[[subCategory]].vue").then(m => m.default || m)
  },
  {
    name: "sandbox-api-server-rendered",
    path: "/sandbox/api-server-rendered",
    component: () => import("/app/src/pages/sandbox/api-server-rendered.vue").then(m => m.default || m)
  },
  {
    name: "sandbox-device-detection",
    path: "/sandbox/device-detection",
    component: () => import("/app/src/pages/sandbox/device-detection.vue").then(m => m.default || m)
  },
  {
    name: "sandbox",
    path: "/sandbox",
    component: () => import("/app/src/pages/sandbox/index.vue").then(m => m.default || m)
  },
  {
    name: "sandbox-language",
    path: "/sandbox/language",
    component: () => import("/app/src/pages/sandbox/language.vue").then(m => m.default || m)
  },
  {
    name: "sandbox-layout-bff",
    path: "/sandbox/layout-bff",
    component: () => import("/app/src/pages/sandbox/layout-bff.vue").then(m => m.default || m)
  },
  {
    name: "sandbox-notification",
    path: "/sandbox/notification",
    component: () => import("/app/src/pages/sandbox/notification.vue").then(m => m.default || m)
  },
  {
    name: "sandbox-stores",
    path: "/sandbox/stores",
    component: () => import("/app/src/pages/sandbox/stores.vue").then(m => m.default || m)
  },
  {
    name: "sandbox-ui-components",
    path: "/sandbox/ui-components",
    component: () => import("/app/src/pages/sandbox/ui-components.vue").then(m => m.default || m)
  },
  {
    name: "sandbox-useElementVisibility",
    path: "/sandbox/useElementVisibility",
    component: () => import("/app/src/pages/sandbox/useElementVisibility.vue").then(m => m.default || m)
  },
  {
    name: "sandbox-vue-use",
    path: "/sandbox/vue-use",
    component: () => import("/app/src/pages/sandbox/vue-use.vue").then(m => m.default || m)
  },
  {
    name: "swagger",
    path: "/swagger",
    meta: indexqdwoTIJZnaMeta || {},
    component: () => import("/app/src/pages/swagger/index.vue").then(m => m.default || m)
  }
]