export enum LaunchStatus {
  Preview = 'preview',
  Prelaunch = 'prelaunch',
  Postlaunch = 'postlaunch',
}

export const LAUNCH_CONFIG_PLATFORM = ['web', 'mobile_web'] as const

export const NOTIFICATION_SUBSCRIPTION_LOCAL_STORAGE_KEY =
  'userNotificationSubscriptionData'
