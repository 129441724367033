<template>
  <div class="search-input-dropdown-container">
    <div
      v-if="isDisplayDropdown"
      class="search-overlay"
      @click="onOverlayClick"
    />
    <div
      class="search-input-container"
      :class="{ 'search-input-focus': isDisplayDropdown }"
    >
      <UiHeaderSearch
        :class="{ 'search-input-focus': isDisplayDropdown }"
        :placeholder="$t('layout.header.search.placeholder')"
        :value="searchQuery"
        class="search-input"
        name="search"
        @close="onClose"
        @input-focus="onInputFocus"
        @input="onInput"
        @submit="onSubmit"
      />
      <div class="cancel-cta is-mobile-and-tablet-only" @click="onClose">
        <UiLabel :text="$t('layout.header.search.cancel')" class="body-m" />
      </div>
    </div>
    <div
      v-if="isDisplayDropdown"
      :ref="el => setContainerHeight(el as HTMLElement)"
      class="search-dropdown"
    >
      <template v-if="isSearching">
        <div class="loader-container">
          <UiLoader size="50px" width="7px" />
        </div>
      </template>
      <template v-else-if="isSearchResultFetched">
        <LayoutHeaderSearchNavigationSearchResult
          :query="searchQuery.toString()"
          v-bind="searchResults"
        />
      </template>
      <template v-else>
        <LayoutHeaderSearchNavigationSearchSuggestion
          :recent-searches="headerSearchStore.getRecentSearches"
          :popular-searches="popularSearches"
          :query="searchQuery.toString()"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useQuery } from '@tanstack/vue-query'
import { debounce } from 'lodash-es'
import UiHeaderSearch from '@sephora-asia/ui-header-search'
import UiLabel from '@sephora-asia/ui-label/'
import UiLoader from '@sephora-asia/ui-loader'

const headerSearchStore = useHeaderSearchStore()
const searchQuery = ref<string | number>('')
const debouncedSearchQuery = ref('')
const isBodyFixed = ref(false)
const isDisplayDropdown = ref(false)
const { trackEvent } = useGtmTrack()

const isSearching = computed(
  () => isPopularSearchesLoading.value || isSearchResultLoading.value
)

const { data: popularSearches, isLoading: isPopularSearchesLoading } = useQuery(
  {
    ...useGetPopularQueryQuery(),
    enabled: computed(() => isDisplayDropdown.value),
  }
)

const {
  data: searchResults,
  isLoading: isSearchResultLoading,
  isFetched: isSearchResultFetched,
} = useQuery({
  ...useGetSearchQuery(debouncedSearchQuery),
  enabled: computed(() => debouncedSearchQuery.value.toString().length > 2),
})

useHead({
  bodyAttrs: {
    class: computed(() => (isBodyFixed.value ? 'search-dropdown-fixed' : '')),
  },
})

function onClose() {
  isDisplayDropdown.value = false
  isBodyFixed.value = false
  searchQuery.value = ''
  debouncedSearchQuery.value = ''
}

function onInput(value: string | number) {
  if (searchQuery.value === value.toString().trim()) return

  searchQuery.value = value.toString().trim()

  debouncedSearch(searchQuery.value)
}

const debouncedSearch = debounce((value: string | number) => {
  debouncedSearchQuery.value = value.toString()
  onInputTracking()
}, 250)

function onInputFocus() {
  isBodyFixed.value = true
  isDisplayDropdown.value = true
  headerSearchStore.fetchRecentSearches()
}

function onOverlayClick() {
  isBodyFixed.value = false
  isDisplayDropdown.value = false
}

function onSubmit() {
  if (searchQuery.value.toString().length) {
    headerSearchStore.saveRecentSearches({
      name: searchQuery.value.toString(),
      slug: `/search?q=${encodeURI(searchQuery.value.toString())}`,
    })

    isDisplayDropdown.value = false
    isBodyFixed.value = false

    onSubmitTracking()

    // TODO: change to router when search page is migrated to SWA
    window.location.href = `/search?q=${encodeURI(searchQuery.value.toString())}`
  }
}

function onInputTracking() {
  trackEvent({ event: 'search_menu_start_input_filling_ssr' })
}

function onSubmitTracking() {
  trackEvent({
    event: 'search_menu_submit_click_ssr',
    params: {
      search_term: searchQuery.value.toString(),
    },
  })
}

function setContainerHeight(element: HTMLElement | null) {
  if (!element) return

  const getBoundingClientRect = (element as HTMLElement).getBoundingClientRect()
  const offsetTop = getBoundingClientRect.top

  // setting full container height for only mobile and tablet view
  const clientWidth = document.documentElement.clientWidth
  if (clientWidth < 1024) {
    element.style.height = `calc(100vh - ${offsetTop}px)`
  }
}
</script>

<style lang="scss" scoped>
.search-input-dropdown-container {
  width: 100%;
  position: relative;

  .search-overlay {
    position: fixed;
    inset: 0;
    z-index: 1;

    @include media(mobile, tablet) {
      background-color: var(--color-base-black);
      opacity: 0.6;
    }
  }

  .search-input-container {
    display: flex;
    align-items: center;
    gap: 12px;

    @include media(mobile, tablet) {
      position: relative;
      z-index: 2;
      background-color: var(--color-base-white);
      padding: 4px 16px;

      &.search-input-focus {
        padding: 12px 16px;
      }
    }

    .cancel-cta {
      display: none;
      margin: 8px 0;
    }

    &.search-input-focus {
      .cancel-cta {
        display: flex;
      }
    }
  }

  .search-input,
  .search-dropdown {
    z-index: 2;
  }

  .search-dropdown {
    @extend .background-shadow;
    display: flex;
    width: 736px;
    position: absolute;
    right: 0;
    border-radius: 8px;
    background-color: var(--color-base-white);

    @include media(mobile, tablet) {
      z-index: var(--size-z-index-modal);
      width: 100%;
      border-radius: 0;
      box-shadow: none;
    }

    .loader-container {
      display: flex;
      justify-content: center;
      padding: 32px;
      width: 100%;
    }
  }
}
</style>
