<template>
  <div class="sidebar-item-container">
    <UiLabel
      :icons="{
        left: {
          name: 'help-outlined',
        },
      }"
      :text="$t('layout.header.help')"
      class="icon-label sidebar-item body-m"
      url="/faq"
    />
    <UiLabel
      v-show="isUserLoggedIn"
      :icons="{
        left: {
          name: 'logout',
        },
      }"
      :text="$t('layout.header.logout')"
      :url="useRoute().fullPath"
      class="icon-label sidebar-item body-m"
      @click.prevent="signOut"
    />
  </div>
</template>

<script lang="ts" setup>
import UiLabel from '@sephora-asia/ui-label'

defineProps<{
  isUserLoggedIn?: boolean
}>()

const { signOut } = useAuth()
</script>

<style lang="scss" scoped>
.icon-label {
  :deep(.icon-container) {
    margin-right: 12px;
  }
}

.sidebar-item-container {
  .sidebar-item,
  :deep(.accordion-header) {
    padding: 12px 20px;
  }

  .accordion-items-container {
    display: flex;
    flex-direction: column;

    .accordion-item {
      padding-left: 20px;
    }
  }
}
</style>
