import type { Router, RouteRecordNameGeneric } from 'vue-router'

const updateRouteQueryWithLocale = (
  locale: string,
  defaultLocale: string,
  router: Router
) => {
  const currentRouteName: NonNullable<RouteRecordNameGeneric> =
    router.currentRoute.value.name || ''

  if (!router.hasRoute(currentRouteName)) return

  let newRoute = router.currentRoute.value.path

  const query = {
    ...router.currentRoute.value.query,
    language: locale,
  } as Record<string, string>

  if (defaultLocale === locale) {
    delete query.language
  }

  if (Object.keys(query).length) {
    newRoute = `${useRoute().path}?${new URLSearchParams(query).toString()}`
  }

  window.location.replace(newRoute)
}

export { updateRouteQueryWithLocale }
