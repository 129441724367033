<template>
  <div class="app-container">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
const { countryCode, getCurrentLocale } = useI18nLocale()
const { t } = useI18n()

useHead({
  titleTemplate: title =>
    title
      ? `${title} | ${t(`root.seoMeta.title.${countryCode}`)}`
      : t(`root.seoMeta.title.${countryCode}`),
  htmlAttrs: {
    lang: () => getCurrentLocale(),
  },
  meta: [
    { charset: 'utf-8', 'data-n-head': 'ssr' }, // data-n-head: ssr is used by GTM to identify if it is SSR or not
    {
      name: 'description',
      content: () => t(`root.seoMeta.description.${countryCode}`),
    },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { name: 'author', content: 'SEPHORA' },
    { name: 'theme-color', content: '#000' },
    {
      property: 'og:image',
      content:
        'https://static-reg.lximg.com/assets/sephora_og_image-34f1169559fdb47662226655a01e2f90dabe8622664f82b7f9b4ed5907237b3d.jpg',
      id: 'og-image',
      name: 'og:image',
    },
    {
      property: 'og:type',
      content: 'website',
      id: 'og-type',
      name: 'og:type',
    },
    { property: 'og:site_name', content: 'Sephora', id: 'og-site-name' },
    { property: 'al:web:should_fallback', content: 'false' },
  ],
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: '/favicon.ico',
    },
    {
      rel: 'preconnect',
      href: 'https://sessions.bugsnag.com',
    },
  ],
})
</script>

<style lang="scss">
@use '@sephora-asia/ui-design-tokens/css/tokens.css' as *;
@use '@sephora-asia/ui-styles/assets/css/minireset.css';

html,
body {
  height: 100%;
  width: 100%;
  overflow: unset;
  -webkit-text-size-adjust: 100%;
}

body {
  &.sidebar-navigation-fixed,
  &.search-dropdown-fixed,
  &.mweb-product-listing-filters-fixed {
    @include media(mobile, tablet) {
      overflow: hidden;
    }
  }
}

.section {
  display: flex;
  justify-content: center;
  padding: 0 16px;

  .container {
    max-width: 1320px;
    width: 100%;
  }

  &.full-width {
    padding: 0;

    .container {
      max-width: none;
    }
  }
}

.app-container {
  height: 100%;
}

#__nuxt {
  height: 100%;
}

@include mobile {
  #credential_picker_iframe {
    z-index: 9999999999 !important;
  }
}
</style>
