import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'
import { queryOptions } from '@tanstack/vue-query'

export function useGetPopularQueryQuery(
  options?: NitroFetchOptions<NitroFetchRequest>
) {
  const { $popularQueryRepository } = useNuxtApp()
  return queryOptions({
    queryKey: ['popular-query', options],
    queryFn: () => $popularQueryRepository.index(unref(options)),
  })
}
