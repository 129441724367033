export interface SocialAuthPayload {
  provider: SocialAuthProviders
  providerToken: string
  trackingLabel?: string
  mergeCart: boolean
  forterToken?: string | null
}

export enum SocialAuthProviders {
  GOOGLE = 'google',
}
