import { queryOptions } from '@tanstack/vue-query'
import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'

export const useGetBookingServicesBeautyServiceQuery = (
  options?: NitroFetchOptions<NitroFetchRequest>
) => {
  const { $bookingServicesBeautyServicesRepository } = useNuxtApp()
  return queryOptions({
    queryKey: ['beauty-service', options],
    queryFn: () =>
      $bookingServicesBeautyServicesRepository.index(unref(options)),
  })
}
