import type { $Fetch } from 'nitropack'
import type { QueriesLock } from '../../utils/queries-lock'
import type { NuxtError } from '#app'

export const useAuthInterceptor = (
  url: Parameters<$Fetch>[0],
  params: Parameters<$Fetch>[1],
  lock: QueriesLock
) => {
  const app = useNuxtApp()
  const { $defaultFetchProvider } = app
  const { updateTokens, getAccessToken } = useAuthTokens()

  async function interceptError(error: NuxtError): ReturnType<$Fetch> {
    if (
      error.statusCode !== 401 ||
      (import.meta.server && !getAccessToken()) ||
      (import.meta.client && !useAuthCookie().jwtData.value)
    ) {
      throw error
    }

    if (lock.isLocked()) {
      return lock.wait(() =>
        app.runWithContext(() => $defaultFetchProvider(url, params))
      )
    }

    try {
      lock.lock()
      await updateTokens()

      return app.runWithContext(() => $defaultFetchProvider(url, params))
    } finally {
      lock.unlock()
    }
  }

  return { interceptError }
}
