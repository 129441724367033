<template>
  <div class="homepage-container">
    <WelcomeMat />
    <ContentfulDynamicPage :page-type="PageMetaKey.Homepage" />
  </div>
</template>

<script lang="ts" setup>
import { PageMetaKey } from '~/types/pages/page-meta-key.types'
import { PageType } from '~/types/pages/page-type.types'

const { setPageType } = usePageMetaStore()

definePageMeta({
  key: PageMetaKey.Homepage,
})

onMounted(() => {
  setPageType(PageType.CmsPage)
})
</script>
<style lang="scss" scoped>
.homepage-container {
  display: flex;
  flex-direction: column;
  gap: 48px;

  @include mobile {
    gap: 24px;
  }
}
</style>
