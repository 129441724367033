export default defineNuxtPlugin({
  name: 'client-flow-token-query-plugin',
  dependsOn: ['default-fetch-provider-plugin'],
  async setup() {
    const { $defaultFetchProvider } = useNuxtApp()

    const queryTokenWithClientFlow = () =>
      $defaultFetchProvider('/api/web/v1/auth/token', {
        method: 'POST',
        body: {
          grant_type: 'refresh_token',
          flow: 'client',
        },
      })

    return {
      provide: {
        queryTokenWithClientFlow,
      },
    }
  },
})
