<template>
  <UiAccordion v-if="tree.children.length > 0" :has-toggle-icon="true">
    <template #header>
      <UiLabel :text="tree.text" class="body-m" />
    </template>
    <template #content>
      <div class="accordion-items-container">
        <span
          v-for="link in tree.children"
          :key="link.to"
          class="accordion-item body-m"
        >
          <LayoutHeaderSidebarNavigationCategoryTree
            v-if="link.children.length > 0"
            :tree="link"
          />
          <UiLabel
            v-else
            class="sidebar-item body-m"
            :text="link.text"
            :url="link.to"
          />
        </span>
      </div>
    </template>
  </UiAccordion>
  <UiLabel
    v-else
    class="sidebar-item body-m"
    :text="tree.text"
    :url="tree.to"
    v-bind="categoryAttrs"
  />
</template>

<script lang="ts" setup>
import UiLabel from '@sephora-asia/ui-label'
import UiAccordion from '@sephora-asia/ui-accordion'

interface CategoryTree {
  text: string
  to: string
  children: CategoryTree[]
}

const props = defineProps<{ tree: CategoryTree }>()

const categoryAttrs = computed(
  () =>
    props.tree.text === 'Brands' && {
      'data-analytics-id': 'side-menu',
      'data-analytics-label': 'brands > Brands',
    }
)
</script>

<style lang="scss" scoped>
.sidebar-item,
:deep(.accordion-header) {
  padding: 12px 20px;
}

.accordion-items-container {
  display: flex;
  flex-direction: column;

  .accordion-item {
    padding-left: 20px;
  }
}
</style>
