export default function useCountryHelper() {
  const { $i18n } = useNuxtApp()

  const getCountryShortName = (countryCode: string) => {
    const key = `countries.${countryCode.toUpperCase()}.shortName`
    const shortName = $i18n.t(key)
    return key === shortName ? null : shortName
  }

  const getCountryFullName = (countryCode: string) => {
    const key = `countries.${countryCode.toUpperCase()}.fullName`
    const fullName = $i18n.t(key)
    return key === fullName ? null : fullName
  }

  return {
    getCountryShortName,
    getCountryFullName,
  }
}
