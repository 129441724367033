import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'
import { queryOptions } from '@tanstack/vue-query'

export function useGetSearchQuery(
  query: MaybeRef<string>,
  options?: NitroFetchOptions<NitroFetchRequest>
) {
  const { $searchRepository } = useNuxtApp()
  return queryOptions({
    queryKey: ['search', query, options],
    queryFn: () =>
      $searchRepository.index({
        query: {
          query: unref(query),
        },
        ...unref(options),
      }),
  })
}
