import { isEmpty } from 'lodash-es'

export interface PreviewLayoutMeta {
  country: string
  locale: string
  isLoggedIn: boolean
  selectedUserSegments: string[]
}

/*
  Symbol makes sure uniquenes of the key and avoids accidental override of provided value.
*/
export const injectionKey = Symbol('preview-options-injection-key')

export default function usePreviewLayoutMeta() {
  const previewOptions = inject<Ref<PreviewLayoutMeta> | undefined>(
    injectionKey,
    undefined
  )

  const isPreviewMode = computed(() => !isEmpty(previewOptions?.value))

  const previewQueries = computed(() => {
    const userSegments = previewOptions?.value.selectedUserSegments || []

    return {
      ...(isPreviewMode.value && {
        user_segments: userSegments?.join(','),
      }),
    }
  })

  const previewHeaders = computed(() => {
    return {
      ...(isPreviewMode.value && {
        'Accept-Language': `${previewOptions?.value.locale}-${previewOptions?.value.country}`,
        'X-Preview-Mode': 'true',
      }),
    }
  })

  return {
    isPreviewMode,
    previewQueries,
    previewOptions,
    previewHeaders,
  }
}
