import type { LocationQuery } from 'vue-router'
import {
  VALID_COUNTRIES,
  AVAILABLE_LANGUAGES,
  DEFAULT_COUNTRY_CODE,
  DEFAULT_LANGUAGE_CODE,
} from '../constants/i18n'
import { useI18nCookies } from '../composables/use-i18n-cookies'

const sanitizeAcceptLanguage = (
  xSiteCountry: string | undefined,
  acceptLanguage: string
) => {
  const matchResult = acceptLanguage.match(/^([a-z]{2})-([a-z]{2});?/i)
  if (!matchResult) return
  let [, languageCode, countryCode] = matchResult

  countryCode = xSiteCountry || countryCode || DEFAULT_COUNTRY_CODE

  if (!isValidCountryCode(countryCode)) {
    return `${DEFAULT_LANGUAGE_CODE}-${DEFAULT_COUNTRY_CODE}`
  }

  if (!isLanguageAvailableForCountry(countryCode, languageCode)) {
    languageCode = getDefaultLanguageCodeForCountry(countryCode)
  }

  return `${languageCode.toLowerCase()}-${countryCode.toUpperCase()}`
}

const isValidCountryCode = (countryCode: string) => {
  return VALID_COUNTRIES.includes(countryCode.toUpperCase())
}

const getAvailableLanguagesForCountry = (countryCode: string) =>
  AVAILABLE_LANGUAGES[countryCode.toUpperCase()]

const isLanguageAvailableForCountry = (
  countryCode: string,
  languageCode: string
) =>
  AVAILABLE_LANGUAGES[countryCode.toUpperCase()].includes(
    languageCode.toLowerCase()
  )

const isLanguageInRouteQuery = (query: LocationQuery) =>
  Object.keys(query).length && (query.language || query.language_code)

const getLanguageCodeFromRoutes = (countryCode: string) => {
  const { query } = useRoute()
  if (!isLanguageInRouteQuery(query)) return

  const languageCode = (query.language || query.language_code) as string
  if (!isLanguageAvailableForCountry(countryCode, languageCode)) return
  return languageCode
}

const getLanguageCodeFromCookies = (countryCode: string) => {
  const { languageCookie } = useI18nCookies()
  if (!languageCookie.value) return

  const languageCode = languageCookie.value
    .replace('lng_', '')
    .trim()
    .toLowerCase()
  if (!isLanguageAvailableForCountry(countryCode, languageCode)) return

  return languageCode
}

const getDefaultLanguageCodeForCountry = (countryCode: string) =>
  AVAILABLE_LANGUAGES[countryCode.toUpperCase()][0].toLowerCase()

export {
  sanitizeAcceptLanguage,
  getAvailableLanguagesForCountry,
  isLanguageAvailableForCountry,
  isLanguageInRouteQuery,
  getLanguageCodeFromRoutes,
  getLanguageCodeFromCookies,
  getDefaultLanguageCodeForCountry,
}
