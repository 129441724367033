import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'
import type { ComputedRef } from 'vue'
import { queryOptions } from '@tanstack/vue-query'
import usePreviewLayoutMeta from '~/composables/preview/use-preview-layout-meta'

export function useGetLayoutOptions(
  options?: NitroFetchOptions<NitroFetchRequest>
) {
  const { $layoutRepository } = useNuxtApp()

  return queryOptions({
    queryKey: [layoutEntryQueryKey, options],
    queryFn: () => {
      return $layoutRepository.index(unref(options))
    },
  })
}

export function useGetPreviewLayoutOptions(
  options?: ComputedRef<NitroFetchOptions<NitroFetchRequest>>
) {
  const { $layoutRepository } = useNuxtApp()
  const { previewHeaders, previewQueries, previewOptions } =
    usePreviewLayoutMeta()

  const fetchOptions = computed(() => ({
    ...toValue(options),
    headers: {
      ...toValue(options)?.headers,
      ...previewHeaders.value,
    },
    query: {
      ...toValue(options)?.query,
      ...previewQueries.value,
    },
  }))

  return queryOptions({
    queryKey: [layoutEntryQueryKey, options, previewOptions],
    queryFn: () => {
      return $layoutRepository.index(fetchOptions.value)
    },
  })
}
