const AVAILABLE_LANGUAGES: { [key: string]: string[] } = {
  AU: ['en'],
  HK: ['en', 'zh'],
  ID: ['id', 'en'],
  MY: ['en'],
  NZ: ['en'],
  PH: ['en'],
  SG: ['en'],
  TH: ['th', 'en'],
}

const VALID_COUNTRIES: string[] = [
  'AU',
  'HK',
  'ID',
  'MY',
  'NZ',
  'PH',
  'SG',
  'TH',
]

const COUNTRY_CODE_COOKIE_KEY = 'locale'

const LANGUAGE_CODE_COOKIE_KEY = 'language'

const DEFAULT_COUNTRY_CODE = 'SG'

const DEFAULT_LANGUAGE_CODE = 'en'

const X_SITE_COUNTRY_HEADER_KEY = 'x-site-country'

const COOKIE_OPTS = {
  maxAge: 60 * 60 * 24 * 365, // TODO: Max cookie age TBD
  path: '/',
}

export {
  VALID_COUNTRIES,
  AVAILABLE_LANGUAGES,
  DEFAULT_COUNTRY_CODE,
  DEFAULT_LANGUAGE_CODE,
  X_SITE_COUNTRY_HEADER_KEY,
  COUNTRY_CODE_COOKIE_KEY,
  LANGUAGE_CODE_COOKIE_KEY,
  COOKIE_OPTS,
}
