export class QueriesLock {
  #lock: Promise<void> | null = null
  #resolver: () => void = () => {}

  isLocked = () => this.#lock !== null

  unlock: () => void = () => {
    this.#resolver()
    this.#lock = null
  }

  lock = () => {
    this.#lock = new Promise(resolve => {
      this.#resolver = resolve
    })
  }

  wait = <T>(listener: () => T): Promise<T> => {
    if (this.#lock) {
      return this.#lock.then(listener)
    } else {
      throw Error('Lock is not locked')
    }
  }
}
