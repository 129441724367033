import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ZGw8LlWmeq from "/app/node_modules/nuxt3-notifications/dist/runtime/plugin.mjs";
import plugin_w2VlvAEcpa from "/app/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_t2GMTTFnMT from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import initialize_locale_KJJqwbCbZv from "/app/src/modules/i18n/runtime/plugins/initialize-locale.ts";
import gtm_Z85xdrHB9q from "/app/src/modules/gtm/runtime/plugins/gtm.ts";
import server_flow_token_query_vVobS72k5d from "/app/src/modules/fetch/runtime/plugins/server-flow-token-query.ts";
import client_flow_token_query_7zMfwYnuCO from "/app/src/modules/fetch/runtime/plugins/client-flow-token-query.ts";
import fetch_provider_yHK0r5ujpS from "/app/src/modules/fetch/runtime/plugins/fetch-provider.ts";
import default_fetch_provider_4MBdpW8N46 from "/app/src/modules/fetch/runtime/plugins/default-fetch-provider.ts";
import vue_router_warn_handler_QBcwMDiKW4 from "/app/src/plugins/debug/vue-router-warn-handler.ts";
import global_route_guard_sTSvTPnkzd from "/app/src/plugins/global-route-guard.ts";
import repository_XIC0OQ9uEC from "/app/src/plugins/repository.ts";
import vue_query_JC9gnf7CA1 from "/app/src/plugins/vue-query.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_ZGw8LlWmeq,
  plugin_w2VlvAEcpa,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_t2GMTTFnMT,
  initialize_locale_KJJqwbCbZv,
  gtm_Z85xdrHB9q,
  server_flow_token_query_vVobS72k5d,
  client_flow_token_query_7zMfwYnuCO,
  fetch_provider_yHK0r5ujpS,
  default_fetch_provider_4MBdpW8N46,
  vue_router_warn_handler_QBcwMDiKW4,
  global_route_guard_sTSvTPnkzd,
  repository_XIC0OQ9uEC,
  vue_query_JC9gnf7CA1
]