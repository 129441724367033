import { useQuery } from '@tanstack/vue-query'

export default defineNuxtPlugin({
  name: 'global-route-guard',
  dependsOn: ['vue-query'],
  setup: async () => {
    const {
      data: layoutContent,
      suspense,
      isSuccess,
    } = useQuery({ ...useGetLayoutOptions(), enabled: import.meta.server })

    // Due to hydration, plugin gets re-executed on client side which in turn calls the layout API again.
    import.meta.server && (await suspense())

    addRouteMiddleware(
      'global-route-guard-middleware',
      (to, from) => {
        // To avoid too many redirects
        if (to.path === from.path) return

        const exemptedRoutes =
          layoutContent.value?.config?.smartLinkExemptedPaths ?? []
        let isExemptedRoute = false

        /* In case bad data is passed as the config is coming from the CMS directly.
         * Check if the internal route is exempted from SPA navigation to cause a hard reload
         */
        try {
          if (to?.name && isSuccess.value) {
            isExemptedRoute = exemptedRoutes.some(entry => {
              switch (entry?.type) {
                case 'absolute':
                  return entry?.value === to.path

                case 'regex':
                  return entry?.value && RegExp(entry.value).test(to.path)

                case 'startsWith':
                  return entry?.value && to.path.startsWith?.(entry?.value)

                default:
                  return false
              }
            })
          }
        } catch (error) {
          useBugsnag().notify(
            {
              name: 'Global Route Guard',
              message:
                'Something went wrong while checking for exempted routes.',
              cause: error,
            },
            event => {
              event.addMetadata('to', to)
              event.addMetadata('from', from)
              event.addMetadata('exemptedPaths', exemptedRoutes)
            }
          )
        }

        if (to.matched.length === 0 || isExemptedRoute) {
          return navigateTo(to, { external: true })
        }
        return
      },
      { global: true }
    )
  },
})
