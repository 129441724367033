const COOKIE_OPTS = {
  maxAge: 60 * 60 * 24 * 60,
  sameSite: 'lax' as const,
  secure: true,
  path: '/',
}
const HTTP_ONLY_COOKIE_OPTS = { ...COOKIE_OPTS, httpOnly: true }

export const useAuthCookie = () => {
  const jwtData = useCookie('jwt-data', COOKIE_OPTS)
  const jwtSignature = useCookie('jwt-signature', HTTP_ONLY_COOKIE_OPTS)
  const refreshToken = useCookie('jwt-refresh-token', HTTP_ONLY_COOKIE_OPTS)
  const deviceId = useCookie('device-id')

  const setJwtCookies = (
    newAccessToken: string,
    newRefreshToken: string
  ): void => {
    const [jwtHeaderResp, jwtDataResp, jwtSignatureResp] =
      newAccessToken.split('.')

    jwtData.value = `${jwtHeaderResp}.${jwtDataResp}`
    jwtSignature.value = jwtSignatureResp
    refreshToken.value = newRefreshToken
  }

  return {
    jwtData,
    jwtSignature,
    refreshToken,
    deviceId,
    setJwtCookies,
  }
}
