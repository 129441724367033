<template>
  <div v-if="showLanguageSwitcher" class="sidebar-item-container">
    <UiAccordion :has-toggle-icon="true">
      <template #header>
        <UiLabel
          :icons="{
            left: {
              name: 'globe',
            },
          }"
          :text="getCurrentLocale().toUpperCase()"
          class="icon-label body-m"
        />
      </template>
      <template #content>
        <div class="accordion-items-container">
          <span
            v-for="locale in getAvailableLocales()"
            :key="locale"
            class="accordion-item body-m"
          >
            <UiLabel
              class="sidebar-item"
              :text="$t(`layout.header.language.${locale}`)"
              :url="
                getCurrentLocale() === locale ? undefined : useRoute().fullPath
              "
              @click.prevent="
                getCurrentLocale() === locale
                  ? undefined
                  : setLanguageAndReload(locale)
              "
            />
          </span>
        </div>
      </template>
    </UiAccordion>
  </div>
</template>

<script lang="ts" setup>
import UiLabel from '@sephora-asia/ui-label'
import UiAccordion from '@sephora-asia/ui-accordion'

const { getCurrentLocale, setLanguageAndReload, getAvailableLocales } =
  useI18nLocale()
const showLanguageSwitcher = computed(() => getAvailableLocales().length > 1)
</script>

<style lang="scss" scoped>
.icon-label {
  :deep(.icon-container) {
    margin-right: 12px;
  }
}

.sidebar-item-container {
  .sidebar-item,
  :deep(.accordion-header) {
    padding: 12px 20px;
  }

  .accordion-items-container {
    display: flex;
    flex-direction: column;

    .accordion-item {
      padding-left: 20px;
    }
  }
}
</style>
