import { DEFAULT_COUNTRY_CODE } from '../constants/i18n'
import {
  getCountryCodeFromCookie,
  getCountryCodeFromRequestHeader,
  getCountryCodeFromRoute,
} from '../utils/country-code-helpers'
import {
  getDefaultLanguageCodeForCountry,
  getLanguageCodeFromCookies,
  getLanguageCodeFromRoutes,
} from '../utils/language-code-helpers'
import { i18nRouteMiddleware } from '../middleware/i18n-route'
import { useSharedCountryCode } from '../composables/states'
import { useI18nCookies } from '../composables/use-i18n-cookies'

const getCountryCode = () =>
  getCountryCodeFromRequestHeader() ||
  getCountryCodeFromCookie() ||
  getCountryCodeFromRoute() ||
  DEFAULT_COUNTRY_CODE

const getLanguageCode = (countryCode: string) =>
  getLanguageCodeFromRoutes(countryCode) ||
  getLanguageCodeFromCookies(countryCode) ||
  getDefaultLanguageCodeForCountry(countryCode)

export default defineNuxtPlugin({
  name: 'initialize-locale-plugin',
  async setup() {
    const { setLanguage } = useI18nLocale()
    const sharedCountryCode = useSharedCountryCode()
    const { setCountryCookie } = useI18nCookies()

    const countryCode = getCountryCode()
    const languageCode = getLanguageCode(countryCode)

    sharedCountryCode.value = countryCode
    setCountryCookie(countryCode)
    setLanguage(languageCode)

    addRouteMiddleware(
      'i18n-route-middleware',
      (to, from) => i18nRouteMiddleware(to, from),
      { global: true }
    )
  },
})
