<template>
  <div class="search-navigation section">
    <div class="container">
      <div class="logo-container section-wrapper">
        <div class="menu-icon is-mobile-and-tablet-only left-column">
          <UiHamburgerMenu @display-modal="onDisplayModal">
            <template #content>
              <LayoutHeaderSidebarNavigation
                :config="config"
                :is-user-logged-in="isUserLoggedIn"
              />
            </template>
          </UiHamburgerMenu>
        </div>
        <div class="logo">
          <LayoutHeaderSephoraLogo />
        </div>
        <div class="section-wrapper is-mobile-and-tablet-only right-column">
          <div class="my-account-icon">
            <UiSmartLink to="/account/dashboard">
              <UiIcon
                class="my-account-icon"
                name="account-outlined"
                size="24px"
                :aria-label="$t('layout.header.myAccount')"
              />
            </UiSmartLink>
          </div>
          <div class="bag-icon">
            <UiSmartLink to="/cart">
              <UiIcon
                class="bag-icon"
                name="bag-outlined"
                size="24px"
                :aria-label="
                  $t('layout.header.bag.itemsCountAriaLabel', {
                    count: lineItemsCountData.lineItemsCount,
                  })
                "
                :badge-content="lineItemsCountData.lineItemsCount"
              />
            </UiSmartLink>
          </div>
        </div>
      </div>

      <div class="section-wrapper">
        <LayoutHeaderSearchNavigationSearchInputDropdown />
        <div class="promotion-banner-container is-desktop-and-above">
          <LayoutHeaderPromotionBanner
            v-if="promotionBanners"
            :promotions="promotionBanners"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import UiIcon from '@sephora-asia/ui-icon'
import UiSmartLink from '@sephora-asia/ui-smart-link'
import UiHamburgerMenu from '@sephora-asia/ui-hamburger-menu'
import type { ConfigDTO } from '~/types/api/bff/web/layout/layout.types'
import type { PromotionBannerDTO } from '~/types/api/bff/web/contentful/component.types'

const props = defineProps<{
  config?: ConfigDTO
  isUserLoggedIn?: boolean
  currentUserId?: string
  promotionBanners?: PromotionBannerDTO[]
}>()

const isBodyFixed = ref(false)
const { data: lineItemsCountData } = await useBag(props.currentUserId)

useHead({
  bodyAttrs: {
    class: computed(() => {
      if (isBodyFixed.value) return 'sidebar-navigation-fixed'
      return ''
    }),
  },
})

function onDisplayModal(value: boolean) {
  isBodyFixed.value = value
}
</script>

<style lang="scss" scoped>
.search-navigation {
  display: flex;
  height: 80px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  background-color: var(--color-base-white);
  padding: 0;

  @include media(mobile, tablet) {
    height: auto;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;

    @include media(mobile, tablet) {
      flex-direction: column;
    }

    .section-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
    }

    .logo-container {
      padding: 0 16px;

      @include media(mobile, tablet) {
        height: 40px;
        justify-content: space-between;
        width: 100%;

        .left-column,
        .right-column {
          width: 25%;
        }

        .right-column {
          justify-content: flex-end;
        }
      }
    }
  }
}
</style>
