import { useGtm } from '@gtm-support/vue-gtm'

interface Event {
  event: string
}
export const useGtmTrack = () => {
  const $gtm = useGtm()
  const { isPreview } = useBaseRoutes()

  const trackEvent = (e: Event & Record<string, unknown>) => {
    if ($gtm?.enabled() && !isPreview.value) {
      const { event, ...props } = e
      window.dataLayer?.push({
        event,
        ...props,
      })
    }
  }

  return {
    trackEvent,
    $gtm,
  }
}
