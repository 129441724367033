import { queryOptions } from '@tanstack/vue-query'

import type {
  BagDataDTO,
  BagItemsCountDTO,
} from '~/types/api/bff/web/cart/bag.types'

export const useGetBagDataQuery = (
  userId?: MaybeRefOrGetter<string | undefined>
) => {
  const { $bagRepository } = useNuxtApp()

  return queryOptions<BagDataDTO>({
    queryKey: getBagDataQueryKey(userId),
    queryFn: async () => await $bagRepository.index(),
  })
}

export const useGetItemsCountQuery = (
  userId?: MaybeRefOrGetter<string | undefined>
) => {
  const { $bagItemsCountRepository } = useNuxtApp()

  return queryOptions<BagItemsCountDTO>({
    queryKey: getItemsCountQueryKey(userId),
    queryFn: async () => await $bagItemsCountRepository.index(),
  })
}
