import { defineStore } from 'pinia'
import { PageType } from '~/types/pages/page-type.types'

export const usePageMetaStore = defineStore('pageMetaStore', () => {
  const campaignName: Ref<string | undefined> = ref('')
  const pageType: Ref<PageType | undefined> = ref(PageType.CmsPage)

  const setCampaignName = (name: string | undefined) => {
    campaignName.value = name
  }

  const setPageType = (key: PageType | undefined) => {
    pageType.value = key
  }

  return {
    campaignName,
    setCampaignName,
    pageType,
    setPageType,
  }
})
