import { jwtDecode } from 'jwt-decode'
import { get } from 'lodash-es'
import type { LocationQueryRaw } from 'vue-router'

export const isSephoraEmail = (email: string) =>
  email && (/.*@sephora\..*$/.test(email) || /.*@*\.sephora\..*$/.test(email))

export const redirectToSignIn = (query?: LocationQueryRaw) =>
  navigateTo(
    {
      path: '/sign_in',
      query,
    },
    { external: true }
  )

export const getJwtPayload = (token: string) => {
  try {
    return jwtDecode(token)
  } catch {
    return null
  }
}

export const getEmailFromJwt = (token: string) =>
  get(getJwtPayload(token), 'email', '')

export const clearLocalStorage = (keysToRemove: string[]): void => {
  Object.keys(localStorage).forEach((key: string) => {
    if (keysToRemove.some((removalKey: string) => key.startsWith(removalKey))) {
      localStorage.removeItem(key)
    }
  })
}
