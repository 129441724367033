import type { $Fetch } from 'nitropack'

const STATIC_HEADERS = {
  'Content-Type': 'application/json',
  'X-Platform': 'web',
  'X-App-Platform': 'web_desktop',
  'X-App-Version': '1.0.0',
  'X-SWA': 'web',
}

export default defineNuxtPlugin({
  name: 'default-fetch-provider-plugin',
  async setup() {
    const defaultFetchProvider = async <T>(
      url: Parameters<$Fetch>[0],
      options: Parameters<$Fetch>[1]
    ): ReturnType<$Fetch<T>> => {
      const cookie = useRequestHeaders(['cookie'])

      const { getAcceptLanguage, countryCode } = useI18nLocale()

      return $fetch.create({
        headers: {
          ...STATIC_HEADERS,
          ...cookie,
          'Accept-Language': getAcceptLanguage(),
          'X-Site-Country': countryCode.toLowerCase(),
        },
        retry: false,
      })(url, options)
    }

    return {
      provide: {
        defaultFetchProvider,
      },
    }
  },
})
