import type {
  ServerFlowParams,
  ServerFlowResponse,
} from '~/types/plugins/token-queries.types'
import type { $Fetch } from 'nitropack'

const getServerFlowQueryParams = (
  params: ServerFlowParams
): Parameters<$Fetch>[1] => ({
  method: 'POST',
  body: {
    grant_type: 'refresh_token',
    flow: 'app',
    refresh_token: params.refreshToken,
    device_id: params.deviceId,
  },
  headers: {
    Authorization: `Bearer ${params.token}`,
    Cookie: '',
  },
})

export default defineNuxtPlugin({
  name: 'server-flow-token-query-plugin',
  dependsOn: ['default-fetch-provider-plugin'],
  async setup() {
    const app = useNuxtApp()
    const { $defaultFetchProvider } = app

    const queryTokenWithServerFlow = (
      params: ServerFlowParams
    ): Promise<ServerFlowResponse> =>
      app.runWithContext(() =>
        $defaultFetchProvider<ServerFlowResponse>(
          '/api/web/v1/auth/token',
          getServerFlowQueryParams(params)
        )
      )

    return {
      provide: {
        queryTokenWithServerFlow,
      },
    }
  },
})
