import {
  AVAILABLE_LANGUAGES,
  X_SITE_COUNTRY_HEADER_KEY,
} from '../constants/i18n'
import { useI18nCookies } from '../composables/use-i18n-cookies'

const isCountryCodeValid = (countryCode: string) =>
  Object.keys(AVAILABLE_LANGUAGES).includes(countryCode.toUpperCase().trim())

const getCountryCodeFromHostname = (host: string) => {
  const urlWithPortRemoved = host.replace(/:*[0-9]/g, '') // Remove port if any
  const urlWithCountryCode = urlWithPortRemoved.match(/-[a-z]{2}\./) // For staging URLs like luxola-development-th.sephora-asia.net
  const countryCode = urlWithCountryCode
    ? urlWithCountryCode[0].replace(/[^a-zA-Z]/g, '')
    : (urlWithPortRemoved.split('.').pop()?.trim() ?? '')

  if (!isCountryCodeValid(countryCode)) return
  return countryCode
}

const getCountryCodeFromXSiteCountry = (countryCode: string) => {
  if (!isCountryCodeValid(countryCode)) return
  return countryCode
}

const getCountryCodeFromRequestHeader = () => {
  const headers = useRequestHeaders()
  if (!headers) return
  return (
    (headers.host && getCountryCodeFromHostname(headers.host)) ||
    (headers[X_SITE_COUNTRY_HEADER_KEY] &&
      getCountryCodeFromXSiteCountry(headers[X_SITE_COUNTRY_HEADER_KEY]))
  )
}

const getCountryCodeFromCookie = () => {
  const { countryCookie } = useI18nCookies()
  if (!countryCookie.value || !isCountryCodeValid(countryCookie.value)) return
  return countryCookie.value
}

const getCountryCodeFromRoute = () => {
  const { query } = useRoute()
  if (
    !Object.keys(query).length ||
    !query.country_code ||
    !isCountryCodeValid(query.country_code as string)
  ) {
    return
  }
  return query.country_code as string
}

export {
  isCountryCodeValid,
  getCountryCodeFromRequestHeader,
  getCountryCodeFromHostname,
  getCountryCodeFromXSiteCountry,
  getCountryCodeFromCookie,
  getCountryCodeFromRoute,
}
