
// @ts-nocheck
import locale__app_src_locales_en_json from "../src/locales/en.json";
import locale__app_src_locales_server_web_en_json from "../src/locales/server/web/en.json";
import locale__app_src_locales_server_legacy_en_json from "../src/locales/server/legacy/en.json";
import locale__app_src_locales_server_mobile_en_json from "../src/locales/server/mobile/en.json";
import locale__app_src_locales_id_json from "../src/locales/id.json";
import locale__app_src_locales_server_web_id_json from "../src/locales/server/web/id.json";
import locale__app_src_locales_server_legacy_id_json from "../src/locales/server/legacy/id.json";
import locale__app_src_locales_server_mobile_id_json from "../src/locales/server/mobile/id.json";
import locale__app_src_locales_th_json from "../src/locales/th.json";
import locale__app_src_locales_server_web_th_json from "../src/locales/server/web/th.json";
import locale__app_src_locales_server_legacy_th_json from "../src/locales/server/legacy/th.json";
import locale__app_src_locales_server_mobile_th_json from "../src/locales/server/mobile/th.json";
import locale__app_src_locales_zh_json from "../src/locales/zh.json";
import locale__app_src_locales_server_web_zh_json from "../src/locales/server/web/zh.json";
import locale__app_src_locales_server_legacy_zh_json from "../src/locales/server/legacy/zh.json";
import locale__app_src_locales_server_mobile_zh_json from "../src/locales/server/mobile/zh.json";


export const localeCodes =  [
  "en",
  "id",
  "th",
  "zh"
]

export const localeLoaders = {
  "en": [{ key: "../src/locales/en.json", load: () => Promise.resolve(locale__app_src_locales_en_json), cache: true },
{ key: "../src/locales/server/web/en.json", load: () => Promise.resolve(locale__app_src_locales_server_web_en_json), cache: true },
{ key: "../src/locales/server/legacy/en.json", load: () => Promise.resolve(locale__app_src_locales_server_legacy_en_json), cache: true },
{ key: "../src/locales/server/mobile/en.json", load: () => Promise.resolve(locale__app_src_locales_server_mobile_en_json), cache: true }],
  "id": [{ key: "../src/locales/id.json", load: () => Promise.resolve(locale__app_src_locales_id_json), cache: true },
{ key: "../src/locales/server/web/id.json", load: () => Promise.resolve(locale__app_src_locales_server_web_id_json), cache: true },
{ key: "../src/locales/server/legacy/id.json", load: () => Promise.resolve(locale__app_src_locales_server_legacy_id_json), cache: true },
{ key: "../src/locales/server/mobile/id.json", load: () => Promise.resolve(locale__app_src_locales_server_mobile_id_json), cache: true }],
  "th": [{ key: "../src/locales/th.json", load: () => Promise.resolve(locale__app_src_locales_th_json), cache: true },
{ key: "../src/locales/server/web/th.json", load: () => Promise.resolve(locale__app_src_locales_server_web_th_json), cache: true },
{ key: "../src/locales/server/legacy/th.json", load: () => Promise.resolve(locale__app_src_locales_server_legacy_th_json), cache: true },
{ key: "../src/locales/server/mobile/th.json", load: () => Promise.resolve(locale__app_src_locales_server_mobile_th_json), cache: true }],
  "zh": [{ key: "../src/locales/zh.json", load: () => Promise.resolve(locale__app_src_locales_zh_json), cache: true },
{ key: "../src/locales/server/web/zh.json", load: () => Promise.resolve(locale__app_src_locales_server_web_zh_json), cache: true },
{ key: "../src/locales/server/legacy/zh.json", load: () => Promise.resolve(locale__app_src_locales_server_legacy_zh_json), cache: true },
{ key: "../src/locales/server/mobile/zh.json", load: () => Promise.resolve(locale__app_src_locales_server_mobile_zh_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../configs/i18n/index.ts?hash=5031450b&config=1" /* webpackChunkName: "__configs_i18n_index_ts_5031450b" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "./configs/i18n/locale-detector.ts",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./configs/i18n/index.ts",
  "locales": [
    {
      "code": "en",
      "name": "english",
      "files": [
        "/app/src/locales/en.json",
        "/app/src/locales/server/web/en.json",
        "/app/src/locales/server/legacy/en.json",
        "/app/src/locales/server/mobile/en.json"
      ],
      "domain": ""
    },
    {
      "code": "id",
      "name": "indonesian",
      "files": [
        "/app/src/locales/id.json",
        "/app/src/locales/server/web/id.json",
        "/app/src/locales/server/legacy/id.json",
        "/app/src/locales/server/mobile/id.json"
      ]
    },
    {
      "code": "th",
      "name": "thai",
      "files": [
        "/app/src/locales/th.json",
        "/app/src/locales/server/web/th.json",
        "/app/src/locales/server/legacy/th.json",
        "/app/src/locales/server/mobile/th.json"
      ]
    },
    {
      "code": "zh",
      "name": "chinese",
      "files": [
        "/app/src/locales/zh.json",
        "/app/src/locales/server/web/zh.json",
        "/app/src/locales/server/legacy/zh.json",
        "/app/src/locales/server/mobile/zh.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "./locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "english",
    "files": [
      {
        "path": "/app/src/locales/en.json"
      },
      {
        "path": "/app/src/locales/server/web/en.json"
      },
      {
        "path": "/app/src/locales/server/legacy/en.json"
      },
      {
        "path": "/app/src/locales/server/mobile/en.json"
      }
    ],
    "domain": ""
  },
  {
    "code": "id",
    "name": "indonesian",
    "files": [
      {
        "path": "/app/src/locales/id.json"
      },
      {
        "path": "/app/src/locales/server/web/id.json"
      },
      {
        "path": "/app/src/locales/server/legacy/id.json"
      },
      {
        "path": "/app/src/locales/server/mobile/id.json"
      }
    ]
  },
  {
    "code": "th",
    "name": "thai",
    "files": [
      {
        "path": "/app/src/locales/th.json"
      },
      {
        "path": "/app/src/locales/server/web/th.json"
      },
      {
        "path": "/app/src/locales/server/legacy/th.json"
      },
      {
        "path": "/app/src/locales/server/mobile/th.json"
      }
    ]
  },
  {
    "code": "zh",
    "name": "chinese",
    "files": [
      {
        "path": "/app/src/locales/zh.json"
      },
      {
        "path": "/app/src/locales/server/web/zh.json"
      },
      {
        "path": "/app/src/locales/server/legacy/zh.json"
      },
      {
        "path": "/app/src/locales/server/mobile/zh.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
