import { queryOptions } from '@tanstack/vue-query'
import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'

export const useGetBrandQuery = (
  options?: NitroFetchOptions<NitroFetchRequest>
) => {
  const { $brandRepository } = useNuxtApp()
  return queryOptions({
    queryKey: ['brand', options],
    queryFn: () => $brandRepository.index(unref(options)),
  })
}
