<template>
  <UiDropdown
    class="nav-dropdown"
    :class="{ disabled: !previewOptions?.isLoggedIn }"
    :disable-toggle="!previewOptions?.isLoggedIn"
  >
    <UiLabel
      text="User Segments"
      :icons="{ left: { name: 'star-outlined' } }"
      class="nav-label body-m"
      color="white"
    />
    <template #content>
      <!-- Display selected segments -->
      <div v-if="selectedSegments.length" class="dropdown-selection-container">
        <div class="dropdown-tag-container">
          <span
            v-for="segmentKey in selectedSegments"
            :key="segmentKey"
            class="dropdown-selection-tag body-s"
          >
            {{ segmentKey }}
          </span>
        </div>
        <UiIcon
          name="delete-outlined"
          size="14px"
          :is-clickable="true"
          @clicked="clearSelectedSegments"
        />
      </div>

      <hr />
      <!-- No segments found -->
      <div v-if="!segmentsData?.length" class="dropdown-item-container">
        <span class="dropdown-title body-m">No user segments found.</span>
      </div>

      <div v-if="segmentsData?.length" class="dropdown-item-container">
        <div
          v-for="segment in segmentsData"
          :key="segment.segment_group"
          class="dropdown-item-container--segment"
        >
          <UiAccordion :has-toggle-icon="true">
            <template #header>
              <UiLabel :text="segment.segment_group" class="headline-m" />
            </template>
            <template #content>
              <div class="accordion-items-container">
                <span
                  v-for="value in segment.unique_values"
                  :key="value"
                  class="accordion-item body-m"
                >
                  <UiLabel
                    class="dropdown-item body-m"
                    :text="value"
                    :icons="getLabelIcons(value)"
                    @click="onUserSegmentClicked(value)"
                  />
                </span>
              </div>
            </template>
          </UiAccordion>
        </div>
      </div>
    </template>
  </UiDropdown>
</template>

<script lang="ts" setup>
import { computed, watch } from 'vue'
import UiDropdown from '@sephora-asia/ui-dropdown'
import UiLabel from '@sephora-asia/ui-label'
import UiIcon from '@sephora-asia/ui-icon'
import UiAccordion from '@sephora-asia/ui-accordion'
import { useQuery } from '@tanstack/vue-query'
import {
  GUEST_USER_SEGMENT,
  SIGNED_IN_USER_SEGMENT,
} from '~/constants/auth.constants'

interface Emit {
  (event: 'selected-segments', value: string[]): void
}

const emit = defineEmits<Emit>()

const { previewOptions } = usePreviewLayoutMeta()

const selectedSegments = computed<string[]>(
  () => previewOptions?.value?.selectedUserSegments || []
)

const { data: segmentsData, suspense: segmentsSuspense } = useQuery(
  useGetAllUserSegmentsQuery()
)

function removeSelectedSegment(segment: string) {
  const newSelectedSegments = [...selectedSegments.value]

  const index = newSelectedSegments.indexOf(segment)

  if (index > -1) {
    newSelectedSegments.splice(index, 1)
  }

  emit('selected-segments', newSelectedSegments)
}

function onUserSegmentClicked(segment: string) {
  const isSelected = selectedSegments.value?.includes(segment)

  if (isSelected) {
    removeSelectedSegment(segment)
  } else {
    emit('selected-segments', [...selectedSegments.value, segment])
  }
}

function clearSelectedSegments() {
  emit(
    'selected-segments',
    previewOptions?.value.isLoggedIn
      ? [SIGNED_IN_USER_SEGMENT]
      : [GUEST_USER_SEGMENT]
  )
}

function addLoginStatusSegmentBasedOnSession() {
  if (previewOptions?.value.isLoggedIn) {
    emit('selected-segments', [SIGNED_IN_USER_SEGMENT])
  } else {
    emit('selected-segments', [GUEST_USER_SEGMENT])
  }
}

function getLabelIcons(value: string) {
  return selectedSegments.value?.includes(value)
    ? { right: { name: 'check-outlined' } }
    : undefined
}

watch(
  () => previewOptions?.value.isLoggedIn,
  addLoginStatusSegmentBasedOnSession,
  { immediate: true }
)

onServerPrefetch(segmentsSuspense)
</script>

<style lang="scss" scoped>
.nav-dropdown {
  .nav-label {
    &:hover {
      text-decoration: underline;
      cursor: pointer;

      :deep(.icon-container) {
        svg {
          cursor: pointer;
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .dropdown-selection-tag {
    color: var(--color-base-red-100) !important;
    padding: 4px 8px !important;
  }

  .dropdown-item-container--segment {
    width: 100%;
  }

  .dropdown-item-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: auto !important;

    .dropdown-item {
      padding: 4px 0px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
  }

  :deep(.dropdown-menu) {
    right: 0;
    padding-top: 8px;

    @include mobile {
      right: unset;
    }

    .dropdown-content {
      max-height: 85vh;
      overflow: scroll;
    }
  }
}
</style>
