import {
  useQueryClient,
  type QueryClient,
  type DefaultError,
} from '@tanstack/vue-query'
import { extractErrorMsgFromFailedRequest } from '~/utils/request-helpers'
import type { MaybeRefOrGetter } from 'vue'
import type { BagDataDTO } from '~/types/api/bff/web/cart/bag.types'

interface AddtoBagParams {
  variantId: MaybeRefOrGetter<string>
  quantity: MaybeRefOrGetter<number>
}

const updateBagQueryData = (
  queryClient: QueryClient,
  data: BagDataDTO,
  userId?: MaybeRefOrGetter<string | undefined>
) => {
  queryClient.setQueryData(getBagDataQueryKey(userId), data)
  queryClient.setQueryData(getItemsCountQueryKey(userId), {
    lineItemsCount: data.lineItems.length,
    token: data.token,
  })
}

export const useAddToBag = (userId?: MaybeRefOrGetter<string | undefined>) => {
  const { $bagLineItemsRepository, $openBagPopUp } = useNuxtApp()
  const client = useQueryClient()
  const { notify } = useNotification()

  return {
    mutationFn: async ({ variantId, quantity }: AddtoBagParams) =>
      await $bagLineItemsRepository.create({
        variantId: toValue(variantId),
        quantity: toValue(quantity),
      }),
    onSuccess: async (data: BagDataDTO) => {
      updateBagQueryData(client, data, userId)
      $openBagPopUp?.()
    },
    onError: (error: DefaultError) => {
      notify({
        type: 'error',
        text: extractErrorMsgFromFailedRequest(error),
      })
    },
  }
}

export const useRemoveFromBag = (
  userId?: MaybeRefOrGetter<string | undefined>
) => {
  const { $bagLineItemsRepository } = useNuxtApp()
  const client = useQueryClient()
  const { notify } = useNotification()

  return {
    mutationFn: async (id: MaybeRefOrGetter<string>) =>
      await $bagLineItemsRepository.delete(toValue(id) as string),
    onSuccess: (data: BagDataDTO) => {
      updateBagQueryData(client, data, userId)
    },
    onError: (error: DefaultError) => {
      notify({
        type: 'error',
        text: extractErrorMsgFromFailedRequest(error),
      })
    },
  }
}
