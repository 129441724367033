<template>
  <div class="menu-navigation section">
    <nav class="container">
      <ul class="menu-navigation-list">
        <li class="menu-navigation-item">
          <UiLabel
            url="/new-arrivals"
            :text="t('layout.header.new')"
            class="menu-navigation-link headline-m"
          />
        </li>

        <li
          v-for="topCategory in categoryTrees"
          :key="topCategory.id"
          class="menu-navigation-item"
        >
          <LayoutHeaderMenuNavigationCategories :top-category="topCategory" />
        </li>

        <li class="menu-navigation-item">
          <LayoutHeaderMenuNavigationBrands :brands="brands || []" />
        </li>

        <li class="menu-navigation-item">
          <UiLabel
            url="/sale"
            :text="t('layout.header.sale')"
            class="menu-navigation-link headline-m"
          />
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { useQuery } from '@tanstack/vue-query'
import type { Category } from '~/types/api/bff/web/categories/categories'
import type { ConfigDTO } from '~/types/api/bff/web/layout/layout.types'
import UiLabel from '@sephora-asia/ui-label'

interface CategoryTree {
  id: string
  text: string
  to: string
  class?: string
  children: CategoryTree[]
}

const props = defineProps<{
  config?: ConfigDTO
}>()

const { t } = useI18n()

const { data: categories, suspense: categorySuspense } = useQuery(
  useGetCategoryQuery({ query: { 'page[size]': 500 } })
)
const { data: brands, suspense: brandSuspense } = useQuery(
  useGetBrandQuery({ query: { 'page[size]': 500, 'filter[hidden]': false } })
)

const categoryTrees = computed(() => {
  const tree: CategoryTree[] = []

  if (categories.value) {
    tree.push(...buildCategoryTree(categories.value))
  }

  if (props.config?.supportGiftCard) {
    tree
      .find(category => category.to === '/categories/gifts')
      ?.children.splice(1, 0, {
        id: 'gift-cards',
        text: t('layout.header.egiftCards'),
        to: '/gift-cards',
        children: [],
      })
  }

  return tree
})

function buildCategoryTree(
  categories: Category[],
  parentId: string | null = null
): CategoryTree[] {
  return categories
    .filter(category => category.parentId === parentId)
    .map(category => {
      const children = buildCategoryTree(categories, category.id)
      if (children.length > 0 && parentId === null) {
        children.unshift({
          id: category.id,
          text: t('layout.header.viewAllCategory', {
            category: category.label,
          }),
          class: 'click-link',
          to: `/categories/${category.slugUrl}`,
          children: [],
        })
      }
      return {
        id: category.id,
        text: category.label,
        to: `/categories/${category.slugUrl}`,
        children,
      }
    })
}

await Promise.allSettled([categorySuspense(), brandSuspense()])
</script>

<style lang="scss" scoped>
.menu-navigation {
  background-color: var(--color-base-white);

  nav.container {
    ul.menu-navigation-list {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .menu-navigation-item {
        :deep(.dropdown) {
          position: unset;
        }

        .menu-navigation-link {
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0px 12px;
        }
      }
    }
  }
}
</style>
