import { LOCAL_SHIPPING_COUNTRIES } from '~/constants/local-shipping-countries.constants'
import { extractCountryCodeFromHeader } from '~/utils/string-helpers'

export default function useUserCountryHelper() {
  const { getCountryFullName } = useCountryHelper()
  const userOriginCountryCode = useState('userOriginCountryCode', () => '')

  const setUserOriginCountryCodeFromHeader = (header: string) => {
    const countryCode = extractCountryCodeFromHeader(header)
    countryCode && setUserOriginCountryCode(countryCode)
  }

  const setUserOriginCountryCode = (countryCode: string) => {
    if (countryCode) userOriginCountryCode.value = countryCode
  }

  const isLocalShippingAvailableForUser = computed(
    () => !!getCountryFullName(userOriginCountryCode.value)
  )

  const userShippingCountryDomain = computed(() => {
    return (
      LOCAL_SHIPPING_COUNTRIES[
        userOriginCountryCode.value as keyof typeof LOCAL_SHIPPING_COUNTRIES
      ]?.domain || ''
    )
  })

  return {
    setUserOriginCountryCodeFromHeader,
    isLocalShippingAvailableForUser,
    userOriginCountryCode,
    userShippingCountryDomain,
  }
}
