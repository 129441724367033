import type { DefaultError } from '@tanstack/vue-query'
import type { H3Event } from 'h3'
import { getCurrencySymbol } from './currency/currency-mapper'
import type { Country } from '~/types/api/utils/country-currency.types'

const tenantsMeta: { [key: string]: string } = {
  id: 'lx_indonesia',
  hk: 'lx_hongkong',
  th: 'lx_thailand',
} as const

/* Might need to add more options to extract the error message from. feel free to update this */
export const extractErrorMsgFromFailedRequest = ({
  data,
  message,
}: DefaultError) =>
  data?.data?.errors?.[0]?.meta?.message || data.data?.error || message

export const getCurrentTenantName = (
  event: H3Event,
  countryCode?: string | null
) => {
  countryCode = (
    countryCode || getCurrentCountryMeta(event).country
  ).toLowerCase()

  return (countryCode && tenantsMeta[countryCode]) || 'lx_default'
}

export const getCurrentCountryMeta = (event: H3Event) => {
  const [languageCode = 'EN', country = 'SG'] =
    getRequestHeader(event, 'accept-language')?.toUpperCase()?.split?.('-') ??
    []
  const language = languageCode.toLowerCase()

  return {
    country: country as Country,
    locale: `${language}-${country}`,
    language: language,
    tenant: tenantsMeta[country?.toLowerCase()] || 'lx_default',
    currencySymbol: getCurrencySymbol(country as Country),
  }
}
