<template>
  <div id="layout-header-container" class="is-sticky">
    <div class="is-sticky">
      <LayoutHeaderAppsflyer
        v-if="appsflyerKey"
        class="is-mobile-only"
        :appsflyer-key="appsflyerKey"
      />
      <LayoutHeaderNotificationToaster class="is-desktop-and-above" />
      <LayoutHeaderSiteWideBanner
        v-if="header?.siteWideBanner"
        v-bind="header.siteWideBanner"
      />
      <LayoutHeaderTopNavigation
        :config="config"
        :first-name="firstName"
        :is-user-logged-in="isUserLoggedIn"
        :loyalty-points="loyaltyPoints"
        :loyalty-tier="loyaltyTier"
        class="is-desktop-and-above is-sticky"
      />
    </div>
    <header class="header-container is-sticky-mobile-and-tablet-only">
      <LayoutHeaderPromotionBanner
        v-if="header?.promotionBannersMobile"
        :promotions="header.promotionBannersMobile"
        class="is-mobile-and-tablet-only"
      />
      <LayoutHeaderSearchNavigation
        :config="config"
        :is-user-logged-in="isUserLoggedIn"
        :promotion-banners="header?.promotionBannersWeb"
        :current-user-id="currentUser?.id"
      />
      <LayoutHeaderMenuNavigation
        :config="config"
        class="is-desktop-and-above"
      />
      <LayoutHeaderNotificationToaster class="is-mobile-and-tablet-only" />
    </header>
  </div>
</template>

<script lang="ts" setup>
import type {
  ConfigDTO,
  HeaderDTO,
} from '~/types/api/bff/web/layout/layout.types'

const props = defineProps<{
  config?: ConfigDTO
  header?: HeaderDTO
  isLoggedIn?: boolean
}>()

const appsflyerKey = props.config?.appsflyerKey

const { isLoggedIn: isLoggedInAPI, currentUser } = await useCurrentUser()

const isUserLoggedIn = computed(() =>
  props.isLoggedIn === undefined ? isLoggedInAPI.value : props.isLoggedIn
)

const firstName = computed(() => currentUser.value?.firstName)
const loyaltyPoints = computed(() => currentUser.value?.loyaltyPoints)
const loyaltyTier = computed(() => currentUser.value?.loyaltyTier)
</script>

<style lang="scss" scoped>
.is-sticky,
.is-sticky-mobile-and-tablet-only {
  position: sticky;
  top: 0;
  z-index: var(--size-z-index-sticky);
}

.is-sticky-mobile-and-tablet-only {
  @include media(desktop, widescreen, fullhd) {
    position: relative;
    z-index: unset;
  }
}

#layout-header-container {
  @include media(desktop, widescreen, fullhd) {
    display: contents;
  }
}
</style>
