import { queryOptions } from '@tanstack/vue-query'
import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'

export const useGetBookingServicesBeautyClassQuery = (
  options?: NitroFetchOptions<NitroFetchRequest>
) => {
  const { $bookingServicesBeautyClassesRepository } = useNuxtApp()
  return queryOptions({
    queryKey: ['beauty-class', options],
    queryFn: () =>
      $bookingServicesBeautyClassesRepository.index(unref(options)),
  })
}
