import { replace } from 'lodash-es'

export function capitalizeEachWord(str: string): string {
  return replace(str.toLowerCase(), /(?:^|\s)+\S/g, g => g.toUpperCase())
}

export function toNormalizeForm(
  str: string,
  form: 'NFC' | 'NFD' | 'NFKC' | 'NFKD' = 'NFD',
  regexEx: RegExp = /[\u0300-\u036F]/g
): string {
  return replace(str.normalize(form), regexEx, '')
}

export function removeDashOrUnderscore(str: string): string {
  return replace(str, /(-|_)/g, ' ')
}

export function extractCountryCodeFromHeader(header: string) {
  return (
    header
      ?.split(',')
      ?.find(pair => pair.trim().startsWith('country_code'))
      ?.slice(-2)
      ?.toUpperCase() || ''
  )
}
