export const LOCAL_SHIPPING_COUNTRIES = {
  AU: {
    country: 'Australia',
    domain: 'www.sephora.com.au',
  },
  ID: {
    country: 'Indonesia',
    domain: 'www.sephora.co.id',
  },
  HK: {
    country: 'Hong Kong (sar}',
    domain: 'www.sephora.hk',
  },
  MY: {
    country: 'Malaysia',
    domain: 'www.sephora.my',
  },
  NZ: {
    country: 'New-Zealand',
    domain: 'www.sephora.nz',
  },
  PH: {
    country: 'Philippines',
    domain: 'www.sephora.ph',
  },
  SG: {
    country: 'Singapore',
    domain: 'www.sephora.sg',
  },
  TH: {
    country: 'Thailand',
    domain: 'www.sephora.co.th',
  },
} as const
