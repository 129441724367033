<template>
  <UiDropdown v-if="showLanguageSwitcher" class="nav-dropdown">
    <UiLabel
      :text="getCurrentLocale().toUpperCase()"
      :icons="{
        left: {
          name: 'globe',
        },
      }"
      class="nav-label body-m"
      color="white"
    />
    <template #content>
      <div class="dropdown-item-container">
        <span
          v-for="locale in getAvailableLocales()"
          :key="locale"
          class="dropdown-item body-m"
        >
          <UiLabel
            :text="$t(`layout.header.language.${locale}`)"
            :url="getCurrentLocale() === locale ? undefined : useRoute().path"
            @click.prevent="
              getCurrentLocale() === locale
                ? undefined
                : setLanguageAndReload(locale)
            "
          />
        </span>
      </div>
    </template>
  </UiDropdown>
</template>

<script lang="ts" setup>
import UiDropdown from '@sephora-asia/ui-dropdown'
import UiLabel from '@sephora-asia/ui-label'

const { getCurrentLocale, setLanguageAndReload, getAvailableLocales } =
  useI18nLocale()
const showLanguageSwitcher = computed(() => getAvailableLocales().length > 1)
</script>

<style lang="scss" scoped>
.nav-label {
  &:hover {
    cursor: pointer;

    :deep(.icon-container) {
      cursor: pointer;
    }
  }
}

.nav-dropdown {
  .dropdown-item-container {
    display: flex;
    width: 200px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .divider-container {
      width: 100%;
    }
  }

  :deep(.dropdown-menu) {
    padding-top: 8px;
  }
}
</style>
