import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { CART_TOKEN_COOKIE_KEY } from '~/server/apps/web/v1/cart/constants/cart.constants'
import type {
  BagDataDTO,
  BagItemsCountDTO,
} from '~/types/api/bff/web/cart/bag.types'

export default async function useBag(
  userId?: MaybeRefOrGetter<string | undefined>
) {
  const cartTokenCookie = useCookie(CART_TOKEN_COOKIE_KEY)
  const queryClient = useQueryClient()

  const isItemsCountQueryEnabled = computed(
    () => !bagData.value && !!cartTokenCookie.value
  )

  const {
    data: bagData,
    error,
    isFetching,
    isSuccess,
  } = useQuery<BagDataDTO>({
    ...useGetBagDataQuery(userId),
    enabled: false,
  })

  const { data: itemsCount, suspense: lineItemsCountSuspense } =
    useQuery<BagItemsCountDTO>({
      ...useGetItemsCountQuery(userId),
      enabled: isItemsCountQueryEnabled,
    })

  const data = computed(() => ({
    ...bagData.value,
    lineItemsCount:
      bagData.value?.lineItems.length ?? itemsCount.value?.lineItemsCount ?? 0,
  }))

  function fetchBagData() {
    if (cartTokenCookie.value)
      queryClient.fetchQuery(useGetBagDataQuery(userId))
  }

  !!cartTokenCookie.value && (await lineItemsCountSuspense())

  return {
    data,
    error,
    fetchBagData,
    isFetching,
    isSuccess,
  }
}
