<template>
  <div class="sidebar-item-container">
    <UiAccordion
      v-if="storeAndEventsLinks.length > 0"
      :has-toggle-icon="true"
      @click="loadBookingServicesData"
    >
      <template #header>
        <UiLabel
          :icons="{
            left: {
              name: 'location-outlined',
            },
          }"
          :text="$t('layout.header.storeAndEvents')"
          class="icon-label body-m"
        />
      </template>
      <template #content>
        <div v-if="!isDataLoaded" class="loader-container">
          <UiLoader size="40px" width="5px" />
        </div>
        <div v-else class="accordion-items-container">
          <span
            v-for="link in storeAndEventsLinks"
            :key="link.to"
            class="accordion-item body-m"
          >
            <UiLabel
              class="sidebar-item"
              :subtext="link.subtext"
              :text="link.text"
              :url="link.to"
            />
          </span>
        </div>
      </template>
    </UiAccordion>
    <UiAccordion
      v-if="bookBeautyServicesLinks.length > 0"
      :has-toggle-icon="true"
      @click="loadBookingServicesData"
    >
      <template #header>
        <UiLabel
          :icons="{
            left: {
              name: 'store-outlined',
            },
          }"
          :text="$t('layout.header.bookBeautyServices')"
          class="icon-label body-m"
        />
      </template>
      <template #content>
        <div v-if="!isDataLoaded" class="loader-container">
          <UiLoader size="40px" width="5px" />
        </div>
        <div v-else class="accordion-items-container">
          <span
            v-for="link in bookBeautyServicesLinks"
            :key="link.to"
            class="accordion-item body-m"
          >
            <UiLabel class="sidebar-item" :text="link.text" :url="link.to" />
          </span>
        </div>
      </template>
    </UiAccordion>
  </div>
</template>

<script lang="ts" setup>
import { useQuery } from '@tanstack/vue-query'
import UiAccordion from '@sephora-asia/ui-accordion'
import UiLabel from '@sephora-asia/ui-label'
import UiLoader from '@sephora-asia/ui-loader'
import type { ConfigDTO } from '~/types/api/bff/web/layout/layout.types'

defineProps<{
  config?: ConfigDTO
}>()

const { t } = useI18n()

const { data: beautyServices, refetch: refetchBeautyServices } = useQuery({
  ...useGetBookingServicesBeautyServiceQuery(),
  enabled: false,
})
const { data: pages, refetch: refetchPages } = useQuery({
  ...useGetBookingServicesPageQuery(),
  enabled: false,
})
const { data: beautyClasses, refetch: refetchBeautyClasses } = useQuery({
  ...useGetBookingServicesBeautyClassQuery(),
  enabled: false,
})
const isDataLoaded = ref(false)

const storeAndEventsLinks = computed(() => {
  const link = [
    {
      text: t('layout.header.discoverStoreEvents'),
      subtext: t('layout.header.discoverStoreEventsDescription'),
      to: '/store-events',
    },
    {
      text: t('layout.header.findAStore'),
      subtext: t('layout.header.findAStoreDescription'),
      to: '/store-locations',
    },
  ]

  if (beautyClasses?.value?.length) {
    link.push({
      text: t('layout.header.beautyClasses'),
      subtext: t('layout.header.beautyClassesDescription'),
      to: '/beauty-classes',
    })
  }

  if (pages?.value?.length) {
    pages.value.forEach(page => {
      link.push({
        text: page.pageTitle,
        subtext: page.dropdownDescription,
        to: `/page/${page.slugUrl}`,
      })
    })
  }

  return link
})

const bookBeautyServicesLinks = computed(() => {
  const link = [
    {
      text: t('layout.header.allBeautyServices'),
      to: '/beauty-services',
    },
  ]

  if (beautyServices?.value?.length) {
    beautyServices.value.forEach(service => {
      link.push({
        text: service.name,
        to: `/beauty-services/${service.slugUrl}`,
      })
    })
  }

  return link
})

async function loadBookingServicesData() {
  if (isDataLoaded.value) {
    return
  }

  await Promise.all([
    refetchBeautyServices(),
    refetchPages(),
    refetchBeautyClasses(),
  ])

  isDataLoaded.value = true
}
</script>

<style lang="scss" scoped>
.icon-label {
  :deep(.icon-container) {
    margin-right: 12px;
  }
}

.sidebar-item-container {
  .sidebar-item,
  :deep(.accordion-header) {
    padding: 12px 20px;
  }

  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
  }

  .accordion-items-container {
    display: flex;
    flex-direction: column;

    .accordion-item {
      padding-left: 20px;
    }
  }
}
</style>
