import {
  getAvailableLanguagesForCountry,
  getDefaultLanguageCodeForCountry,
} from '../utils/language-code-helpers'
import { updateRouteQueryWithLocale } from '../utils/route.helper'
import { useSharedCountryCode } from './states'
import { useI18nCookies } from './use-i18n-cookies'

export const useI18nLocale = () => {
  const { $i18n } = useNuxtApp()
  const router = useRouter()

  const countryCode = useSharedCountryCode()
  const { setLanguageCookie } = useI18nCookies()

  const getCurrentLocale = () => $i18n.locale.value

  const getDefaultLocale = () =>
    getDefaultLanguageCodeForCountry(countryCode.value)

  const getAvailableLocales = () =>
    getAvailableLanguagesForCountry(countryCode.value)

  const getAcceptLanguage = () =>
    `${$i18n.locale.value}-${countryCode.value.toUpperCase()}`

  const setLanguage = async (value: string) => {
    const locale = value.toLowerCase()

    await $i18n.setLocale(locale)
    setLanguageCookie(locale)
  }

  const setLanguageAndReload = async (value: string) => {
    const locale = value.toLowerCase()

    await setLanguage(locale)
    updateRouteQueryWithLocale(locale, getDefaultLocale(), router)
  }

  return {
    countryCode: countryCode.value,
    getCurrentLocale,
    setLanguage,
    setLanguageAndReload,
    getDefaultLocale,
    getAvailableLocales,
    getAcceptLanguage,
  }
}
