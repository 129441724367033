import { queryOptions } from '@tanstack/vue-query'
import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'

export const useGetBookingServicesPageQuery = (
  options?: NitroFetchOptions<NitroFetchRequest>
) => {
  const { $bookingServicesPagesRepository } = useNuxtApp()
  return queryOptions({
    queryKey: ['page', options],
    queryFn: () => $bookingServicesPagesRepository.index(unref(options)),
  })
}
