import { queryOptions } from '@tanstack/vue-query'
import useBaseRoutes from '~/composables/utils/use-base-routes'
import usePreviewLayoutMeta from '~/composables/preview/use-preview-layout-meta'
import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'

export function useGetContentEntry(
  options?: MaybeRefOrGetter<NitroFetchOptions<NitroFetchRequest>>
) {
  const { previewOptions, isPreviewMode, previewHeaders, previewQueries } =
    usePreviewLayoutMeta()

  const { $contentEntriesRepository, $previewContentEntriesRepository } =
    useNuxtApp()
  const { pageType, slugUrl } = useBaseRoutes()

  const repository = isPreviewMode.value
    ? $previewContentEntriesRepository
    : $contentEntriesRepository

  const fetchOptions = computed(() => ({
    ...toValue(options),
    headers: {
      ...toValue(options)?.headers,
      ...previewHeaders.value,
    },
    query: {
      ...toValue(options)?.query,
      ...previewQueries.value,
    },
  }))

  return queryOptions({
    queryKey: [
      contentEntryQueryKey,
      pageType,
      slugUrl,
      fetchOptions,
      previewOptions,
    ],
    queryFn: () =>
      repository.show(`${pageType.value}/${slugUrl.value}`, fetchOptions.value),
  })
}
