import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { CART_TOKEN_COOKIE_KEY } from '~/server/apps/web/v1/cart/constants/cart.constants'
import { JWT_DATA_COOKIE_KEY } from '~/constants/auth.constants'
import { NOTIFICATION_SUBSCRIPTION_LOCAL_STORAGE_KEY } from '~/composables/utils/product-listing/card-container/constants'

import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'
import { clearLocalStorage } from './helpers'

const queriesToClear = [
  userAccountQueryKey[0],
  getWaitlistQueryKey()[0],
  getRemindMeQueryKey()[0],
  getWishlistQueryKey()[0],
  getBagDataQueryKey()[0],
  getItemsCountQueryKey()[0],
]

const queriesToInvalidate = [contentEntryQueryKey, layoutEntryQueryKey]

export default function useAuth() {
  const cartTokenCookie = useCookie(CART_TOKEN_COOKIE_KEY)
  const jwtDataCookie = useCookie(JWT_DATA_COOKIE_KEY)

  const {
    $sessionsRepository,
    $i18n: { t },
  } = useNuxtApp()
  const { notify } = useNotification()
  const queryClient = useQueryClient()

  const { mutate: signOutMutation } = useMutation({
    mutationFn: async (options?: NitroFetchOptions<NitroFetchRequest>) =>
      await $sessionsRepository.delete(undefined, options),
    onSuccess: onSignOutSuccess,
  })

  function signOut(options?: NitroFetchOptions<NitroFetchRequest>) {
    signOutMutation({ ...options })
  }

  function onSignOutSuccess() {
    /* To reset cookie refs present inside components and composables.
     * cookies will be deleted by the server but we need to update refs manually.
     * cookieStore option in useCookie is in experimental stage currently which is capable of handeling these cases.
     * refreshCookie() & cookie.value = null is not working
     */
    cartTokenCookie.value = ''
    jwtDataCookie.value = ''

    // To clear local storage
    clearLocalStorage([NOTIFICATION_SUBSCRIPTION_LOCAL_STORAGE_KEY])

    // To completely wipe out data from cache without trigerring any refetch and update the same on UI.
    queryClient.setQueriesData(
      {
        predicate: query =>
          queriesToClear.includes(query.queryKey?.[0] as string),
        exact: false,
      },
      null
    )

    // Mark queries to be invalidated so that fresh data will be fetched on next request and to refetch active queries.
    queryClient.invalidateQueries({
      predicate: query =>
        queriesToInvalidate.includes(query.queryKey?.[0] as string),
      exact: false,
    })

    notify({
      type: 'success',
      text: t('auth.signedOut'),
    })
  }

  return {
    signOut,
  }
}
