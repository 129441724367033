<template>
  <UiDropdown class="is-hoverable menu-dropdown">
    <UiLabel
      url="/brands"
      :text="$t('layout.header.brands')"
      data-analytics-id="top-menu"
      data-analytics-path="brands"
      data-analytics-label="brands"
      class="menu-navigation-link hover-link headline-m"
    />
    <UiLabel
      url="/brands"
      :text="$t('layout.header.brands')"
      class="menu-navigation-link click-link headline-m"
      @click.prevent
    />
    <template #content>
      <div class="dropdown-item-container section">
        <div class="container">
          <div class="brands-dropdown-container">
            <div class="brands-search-filter">
              <UiInput
                :placeholder="$t('layout.header.searchBrands')"
                :value="brandSearchInput"
                class="brands-search-input"
                name="brand-search"
                type="filter"
                @input="brandSearchInput = $event"
              />
              <nav class="letter-list">
                <template
                  v-for="(results, letter) in searchResult"
                  :key="letter"
                >
                  <a
                    class="letter-link"
                    :class="{ disabled: results.length == 0 }"
                    tabindex="1"
                    :href="`/brands#letter-${letter}`"
                    @click.prevent="searchBrandsByLetter(letter)"
                  >
                    {{ letter }}
                  </a>
                </template>
              </nav>
            </div>
            <div class="brands-group-list">
              <UiLabel
                url="/brands"
                :text="$t('layout.header.viewAllBrands')"
                class="click-link headline-m"
              />
              <UiLabel
                url="/brands/sephora-collection"
                :text="$t('layout.header.sephoraCollection')"
                class="headline-m"
              />
              <section
                ref="brandsSectionContainer"
                class="brands-sections-container"
              >
                <section
                  v-for="(results, letter) in searchResult"
                  :id="'brand-letter-' + letter"
                  :key="letter"
                  class="brands-sections"
                  :class="{ 'is-hidden': results.length == 0 }"
                >
                  <div class="menu-sub-title headline-m">
                    {{ letter }}
                  </div>
                  <ul class="menu-list">
                    <li
                      v-for="brand in results"
                      :key="brand.name"
                      class="menu-list-item"
                    >
                      <UiLabel
                        :url="`/brands/${brand.slugUrl}`"
                        :text="brand.name"
                        data-analytics-id="top-sub-menu"
                        :data-analytics-label="brand.name"
                        :data-analytics-path="`brands > ${brand.slugUrl}`"
                        class="body-m"
                      />
                    </li>
                  </ul>
                </section>
              </section>
            </div>
          </div>
        </div>
      </div>
    </template>
  </UiDropdown>
</template>

<script lang="ts" setup>
import type { Brands } from '~/types/api/bff/web/brands/brands'
import UiDropdown from '@sephora-asia/ui-dropdown'
import UiInput from '@sephora-asia/ui-input'
import UiLabel from '@sephora-asia/ui-label'

interface BrandGroup {
  [key: string]: Brands
}

const props = defineProps<{
  brands: Brands
}>()

const brandsSectionContainer = ref<HTMLElement | null>(null)

const alphabets = Array.from(Array(26)).map((e, i) =>
  String.fromCharCode(i + 97)
)

const firstLetter = alphabets[0]

const brandsByLetters = computed(() => {
  const group: BrandGroup = {}

  alphabets.forEach(letter => {
    group[letter] = props.brands.filter(
      brand => brand.name.charAt(0).toLowerCase() === letter
    )
  })

  return group
})

const brandsStartingWithNumber = computed(() => {
  return props.brands.filter(brand => !isNaN(parseInt(brand.name.charAt(0))))
})

const brandGroup = computed(() => {
  const groups = brandsByLetters.value

  groups[firstLetter] = brandsStartingWithNumber.value.concat(
    groups[firstLetter]
  )

  Object.keys(groups).forEach(key => {
    const brands = groups[key]
    brands.sort((a, b) => a.name.localeCompare(b.name))
  })

  return groups
})

const brandSearchInput = ref<string | number>('')

const searchInputMatcher = computed(() => {
  // escapes invalid character such as `\`, and `*` by adding `\` in front of it
  let input = brandSearchInput.value
    .toString()
    .replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
  input = normalizeAccentedBrandName(input)
  return RegExp(input, 'i')
})

const searchResult = computed(() => {
  const result: BrandGroup = {}

  for (const letter in brandGroup.value) {
    result[letter] = brandGroup.value[letter].filter(brand => {
      return searchInputMatcher.value.test(
        normalizeAccentedBrandName(brand['name'])
      )
    })
  }

  return result
})

function searchBrandsByLetter(letter: string | number): void {
  const scrollTo = document.getElementById(`brand-letter-${letter}`)
  let topOffset = 0
  if (scrollTo && brandsSectionContainer?.value) {
    topOffset = (scrollTo.offsetTop -
      (brandsSectionContainer.value as HTMLElement).offsetTop) as number
    ;(
      document.querySelector('.brands-sections-container') as HTMLElement
    ).scrollTop = topOffset
  }
}

function normalizeAccentedBrandName(brand: string): string {
  return brand
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '')
    .toLowerCase()
}
</script>

<style lang="scss" scoped>
.menu-navigation-link {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0px 12px;

  @media (hover: none) {
    &.hover-link {
      display: none;
    }

    &.click-link {
      display: flex;
    }
  }

  @media (hover: hover) {
    &.hover-link {
      display: flex;
    }

    &.click-link {
      display: none;
    }
  }
}

.menu-dropdown {
  :deep(.dropdown-menu) {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    padding-top: 0;

    .dropdown-content {
      @extend .low-shadow-bottom;
      border-radius: var(--size-radius-none);
    }
  }
}

.dropdown-item-container {
  padding: 24px 16px;
  height: 420px;
  flex-shrink: 0;
  justify-content: center;

  .brands-dropdown-container {
    display: flex;
    flex-direction: row;
    gap: 64px;
    height: 100%;

    .brands-search-filter {
      display: flex;
      width: 240px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;

      .brands-search-input {
        width: 100%;
      }

      .letter-list {
        max-height: 200px;
        overflow-y: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .letter-link {
        @extend .headline-l;
        // 7 letters per row
        width: calc(100% / 7);
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 20px;
        text-transform: uppercase;
        text-decoration: none;
        color: var(--color-base-black);
        text-align: center;

        &.disabled,
        &.disabled:hover {
          color: var(--color-base-grey-30);
          pointer-events: none;
          text-decoration: none;
          /* For IE11/ MS Edge bug */
          display: inline-block;
        }

        &:hover {
          color: var(--color-base-red-100);
          text-decoration: none;
        }
      }
    }

    .brands-group-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      flex: 1 0 0;

      @media (hover: none) {
        & .click-link {
          display: initial;
        }
      }

      @media (hover: hover) {
        & .click-link {
          display: none;
        }
      }

      .brands-sections-container {
        overflow-y: scroll;
        scroll-behavior: smooth;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        width: 100%;

        .brands-sections {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;

          .menu-sub-title {
            text-transform: uppercase;
          }

          .menu-list {
            display: flex;
            align-items: flex-start;
            align-content: flex-start;
            gap: 16px;
            flex-wrap: wrap;

            .menu-list-item {
              min-width: 190px;
            }
          }
        }
      }
    }
  }
}
</style>
