import type { NitroFetchOptions, $Fetch, NitroFetchRequest } from 'nitropack'

interface Repository {
  show: unknown
  index: unknown
  create: unknown
  update: unknown
  replace: unknown
  delete: unknown
}

interface Show<T> {
  show: (
    id: string,
    options?: NitroFetchOptions<NitroFetchRequest>
  ) => Promise<T>
}

interface Index<T> {
  index: (options?: NitroFetchOptions<NitroFetchRequest>) => Promise<T>
}

interface Create<P, T> {
  create: (
    body: P,
    options?: NitroFetchOptions<NitroFetchRequest>
  ) => Promise<T>
}

interface Update<P, T> {
  update: (
    id: string,
    body: P,
    options?: NitroFetchOptions<NitroFetchRequest>
  ) => Promise<T>
}

interface Replace<P, T> {
  replace: (
    body: P,
    id?: string,
    options?: NitroFetchOptions<NitroFetchRequest>
  ) => Promise<T>
}

interface Delete<T> {
  delete: (
    id?: string,
    options?: NitroFetchOptions<NitroFetchRequest>
  ) => Promise<T>
}

type Builder<T> = {
  [P in keyof T]: T[P] extends undefined ? never : T[P]
}

export class RepositoryBuilder<T extends Partial<Repository> = object> {
  #repository: Partial<Repository> = {}

  constructor(
    private fetch:
      | $Fetch<unknown, NitroFetchRequest>
      | $Fetch<unknown, NitroFetchRequest>['raw'],
    private url: string
  ) {
    this.url = this.#sanitizeUrl(url)
  }

  withShow<K>() {
    this.#repository.show = this.#show
    return this as RepositoryBuilder<T & Show<K>>
  }

  withIndex<K>() {
    this.#repository.index = this.#index
    return this as RepositoryBuilder<T & Index<K>>
  }

  withCreate<R, K>() {
    this.#repository.create = this.#create
    return this as RepositoryBuilder<T & Create<R, K>>
  }

  withUpdate<R, K>() {
    this.#repository.update = this.#update
    return this as RepositoryBuilder<T & Update<R, K>>
  }

  withReplace<R, K>() {
    this.#repository.replace = this.#replace
    return this as RepositoryBuilder<T & Replace<R, K>>
  }

  withDelete<K>() {
    this.#repository.delete = this.#delete
    return this as RepositoryBuilder<T & Delete<K>>
  }

  build() {
    return this.#repository as Builder<T>
  }

  #show = (id: string, options: NitroFetchOptions<NitroFetchRequest> = {}) => {
    const href = this.#sanitizeUrl(`${this.url}/${id}`)

    return this.fetch(href, options)
  }

  #index = (options: NitroFetchOptions<NitroFetchRequest> = {}) => {
    return this.fetch(this.url, options)
  }

  #create = (
    body: Body,
    options: NitroFetchOptions<NitroFetchRequest> = {}
  ) => {
    const defaultOptions: Pick<
      NitroFetchOptions<NitroFetchRequest>,
      'method'
    > = {
      method: 'POST',
    }

    return this.fetch(this.url, { ...defaultOptions, ...options, body })
  }

  #update = (
    id: string,
    body: Body,
    options: NitroFetchOptions<NitroFetchRequest> = {}
  ) => {
    const href = this.#sanitizeUrl(`${this.url}/${id}`)
    const defaultOptions: Pick<
      NitroFetchOptions<NitroFetchRequest>,
      'method'
    > = {
      method: 'PATCH',
    }

    return this.fetch(href, { ...defaultOptions, ...options, body })
  }

  #replace = (
    body: Body,
    id?: string,
    options: NitroFetchOptions<NitroFetchRequest> = {}
  ) => {
    const href = id ? this.#sanitizeUrl(`${this.url}/${id}`) : this.url
    const defaultOptions: Pick<
      NitroFetchOptions<NitroFetchRequest>,
      'method'
    > = {
      method: 'PUT',
    }

    return this.fetch(href, { ...defaultOptions, ...options, body })
  }

  #delete = (
    id?: string,
    options: NitroFetchOptions<NitroFetchRequest> = {}
  ) => {
    const href = id ? this.#sanitizeUrl(`${this.url}/${id}`) : this.url

    const defaultOptions: Pick<
      NitroFetchOptions<NitroFetchRequest>,
      'method'
    > = {
      method: 'DELETE',
    }

    return this.fetch(href, { ...defaultOptions, ...options })
  }

  #sanitizeUrl = (url: string) => {
    return url.replace(/\/+$/, '')
  }
}
