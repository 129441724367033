import { queryOptions } from '@tanstack/vue-query'
import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'

export const useGetAllUserSegmentsQuery = (
  options?: MaybeRefOrGetter<NitroFetchOptions<NitroFetchRequest>>
) => {
  const { $seaFalconAllUserSegmentsRepository } = useNuxtApp()

  return queryOptions({
    queryKey: ['all-user-segments', options],
    queryFn: () =>
      $seaFalconAllUserSegmentsRepository.index({
        ...toValue(options),
        headers: {
          ...toValue(options)?.headers,
        },
      }),
  })
}
