import type { RouteLocationNormalizedGeneric } from 'vue-router'

const i18nRouteMiddleware = (
  to: RouteLocationNormalizedGeneric,
  from: RouteLocationNormalizedGeneric
) => {
  const toLanguageQuery = to.query.language as string
  const fromLanguageQuery = from.query.language as string
  const { getDefaultLocale, getCurrentLocale } = useI18nLocale()

  if (import.meta.client) {
    // to append non default language query to the route if it doesn't exist (For SEO Canonical URL purposes)
    if (
      !fromLanguageQuery &&
      !toLanguageQuery &&
      getCurrentLocale() !== getDefaultLocale()
    ) {
      return navigateWithLanguageQuery(to, getCurrentLocale())
    }
    // to allow language query keep appending when navigation happens
    if (fromLanguageQuery && !toLanguageQuery) {
      return navigateWithLanguageQuery(to, fromLanguageQuery)
    }
  }
}

function navigateWithLanguageQuery(
  to: RouteLocationNormalizedGeneric,
  locale: string
) {
  return navigateTo({
    path: to.path,
    query: { ...to.query, language: locale },
  })
}

export { i18nRouteMiddleware }
