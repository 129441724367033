// Vue router directly uses console.warn, thus warning is not handled by vue.app.config.warnHandler
/* eslint-disable no-console */
export default defineNuxtPlugin({
  name: 'vue-router-warn-handler',
  async setup() {
    if (import.meta.server && useRequestHeader('x-disable-router-warning')) {
      useState('x-disable-router-warning', () => true)
    }

    if (!useState('x-disable-router-warning').value) return
    console.log('🐞 [Debug Headers] X-disable-router-warning –– Enabled!')

    const nativeWarn = console.warn.bind(console)
    console.warn = (...args) => {
      if (
        args[0].startsWith(
          '[Vue Router warn]: No match found for location with path'
        )
      ) {
        return
      }
      nativeWarn(...args)
    }
  },
})
